@charset "UTF-8";
/* This files helps with quick spacing of elements  and helper classes*/
/**
 Below i have a loop that goes from 1 to 30 and create spacing helper functions
  p-{NUMBER} -> Generates padding for top,bottom,left,right
  m-{NUMBER} -> Generates margin for top,bottom,left,right
  m{side}-{NUMBER} -> Generates margin for sides i.e (mx-20 , is 20 on the x-axis, mb-20 is 20 at the bottom

  f-{NUMBER} -> Generates fonts in rem (rem is better than px since it is up to the browser to convert the rem to px)
  font-weight-{NUMBER} -> Generates fonts weights multiplied by 100 to include bolds as well
  w-{NUMBER} -> Generates width values
  h-{NUMBER} -> Generates height values
*/
@import url(~slick-carousel/slick/slick.css);
@import url(./fonts/icomoon/style.css);
@import url(./fonts/fontawesome/style.css);
@import url(./fonts/fontawesome-pro/index.css);
@import url(~slick-carousel/slick/slick-theme.css);
.p-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem; }

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem; }

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem; }

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.-mb-1 {
  margin-bottom: -0.25rem; }

.mb-1 {
  margin-bottom: 0.25rem; }

.-mt-1 {
  margin-top: -0.25rem; }

.mt-1 {
  margin-top: 0.25rem; }

.ml-1 {
  margin-left: 0.25rem; }

.mr-1 {
  margin-right: 0.25rem; }

.m-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem; }

.font-weight-100 {
  font-weight: 100; }

.w-2 {
  width: 0.25rem; }

.h-2 {
  height: 0.25rem; }

.p-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.-mb-2 {
  margin-bottom: -0.5rem; }

.mb-2 {
  margin-bottom: 0.5rem; }

.-mt-2 {
  margin-top: -0.5rem; }

.mt-2 {
  margin-top: 0.5rem; }

.ml-2 {
  margin-left: 0.5rem; }

.mr-2 {
  margin-right: 0.5rem; }

.m-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.font-weight-200 {
  font-weight: 200; }

.w-4 {
  width: 0.5rem; }

.h-4 {
  height: 0.5rem; }

.p-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem; }

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem; }

.-mb-3 {
  margin-bottom: -0.75rem; }

.mb-3 {
  margin-bottom: 0.75rem; }

.-mt-3 {
  margin-top: -0.75rem; }

.mt-3 {
  margin-top: 0.75rem; }

.ml-3 {
  margin-left: 0.75rem; }

.mr-3 {
  margin-right: 0.75rem; }

.m-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem; }

.font-weight-300 {
  font-weight: 300; }

.w-6 {
  width: 0.75rem; }

.h-6 {
  height: 0.75rem; }

.p-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem; }

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem; }

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem; }

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.-mb-4 {
  margin-bottom: -1rem; }

.mb-4 {
  margin-bottom: 1rem; }

.-mt-4 {
  margin-top: -1rem; }

.mt-4 {
  margin-top: 1rem; }

.ml-4 {
  margin-left: 1rem; }

.mr-4 {
  margin-right: 1rem; }

.m-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem; }

.font-weight-400 {
  font-weight: 400; }

.w-8 {
  width: 1rem; }

.h-8 {
  height: 1rem; }

.p-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem; }

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

.-mb-5 {
  margin-bottom: -1.25rem; }

.mb-5 {
  margin-bottom: 1.25rem; }

.-mt-5 {
  margin-top: -1.25rem; }

.mt-5 {
  margin-top: 1.25rem; }

.ml-5 {
  margin-left: 1.25rem; }

.mr-5 {
  margin-right: 1.25rem; }

.m-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem; }

.font-weight-500 {
  font-weight: 500; }

.w-10 {
  width: 1.25rem; }

.h-10 {
  height: 1.25rem; }

.p-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem; }

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.-mb-6 {
  margin-bottom: -1.5rem; }

.mb-6 {
  margin-bottom: 1.5rem; }

.-mt-6 {
  margin-top: -1.5rem; }

.mt-6 {
  margin-top: 1.5rem; }

.ml-6 {
  margin-left: 1.5rem; }

.mr-6 {
  margin-right: 1.5rem; }

.m-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem; }

.font-weight-600 {
  font-weight: 600; }

.w-12 {
  width: 1.5rem; }

.h-12 {
  height: 1.5rem; }

.p-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem; }

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem; }

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem; }

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem; }

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem; }

.-mb-7 {
  margin-bottom: -1.75rem; }

.mb-7 {
  margin-bottom: 1.75rem; }

.-mt-7 {
  margin-top: -1.75rem; }

.mt-7 {
  margin-top: 1.75rem; }

.ml-7 {
  margin-left: 1.75rem; }

.mr-7 {
  margin-right: 1.75rem; }

.m-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  margin-left: 1.75rem;
  margin-right: 1.75rem; }

.font-weight-700 {
  font-weight: 700; }

.w-14 {
  width: 1.75rem; }

.h-14 {
  height: 1.75rem; }

.p-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem; }

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem; }

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem; }

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.-mb-8 {
  margin-bottom: -2rem; }

.mb-8 {
  margin-bottom: 2rem; }

.-mt-8 {
  margin-top: -2rem; }

.mt-8 {
  margin-top: 2rem; }

.ml-8 {
  margin-left: 2rem; }

.mr-8 {
  margin-right: 2rem; }

.m-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem; }

.f-8 {
  font-size: 8px; }

.font-weight-800 {
  font-weight: 800; }

.w-16 {
  width: 2rem; }

.h-16 {
  height: 2rem; }

.p-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  padding-left: 2.25rem;
  padding-right: 2.25rem; }

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem; }

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem; }

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem; }

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem; }

.-mb-9 {
  margin-bottom: -2.25rem; }

.mb-9 {
  margin-bottom: 2.25rem; }

.-mt-9 {
  margin-top: -2.25rem; }

.mt-9 {
  margin-top: 2.25rem; }

.ml-9 {
  margin-left: 2.25rem; }

.mr-9 {
  margin-right: 2.25rem; }

.m-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
  margin-left: 2.25rem;
  margin-right: 2.25rem; }

.font-weight-900 {
  font-weight: 900; }

.w-18 {
  width: 2.25rem; }

.h-18 {
  height: 2.25rem; }

.p-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem; }

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.-mb-10 {
  margin-bottom: -2.5rem; }

.mb-10 {
  margin-bottom: 2.5rem; }

.-mt-10 {
  margin-top: -2.5rem; }

.mt-10 {
  margin-top: 2.5rem; }

.ml-10 {
  margin-left: 2.5rem; }

.mr-10 {
  margin-right: 2.5rem; }

.m-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  margin-left: 2.5rem;
  margin-right: 2.5rem; }

.f-10 {
  font-size: 10px; }

.font-weight-1000 {
  font-weight: 1000; }

.w-20 {
  width: 2.5rem; }

.h-20 {
  height: 2.5rem; }

.p-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
  padding-left: 2.75rem;
  padding-right: 2.75rem; }

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem; }

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem; }

.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem; }

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem; }

.-mb-11 {
  margin-bottom: -2.75rem; }

.mb-11 {
  margin-bottom: 2.75rem; }

.-mt-11 {
  margin-top: -2.75rem; }

.mt-11 {
  margin-top: 2.75rem; }

.ml-11 {
  margin-left: 2.75rem; }

.mr-11 {
  margin-right: 2.75rem; }

.m-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
  margin-left: 2.75rem;
  margin-right: 2.75rem; }

.font-weight-1100 {
  font-weight: 1100; }

.w-22 {
  width: 2.75rem; }

.h-22 {
  height: 2.75rem; }

.p-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem; }

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem; }

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem; }

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.-mb-12 {
  margin-bottom: -3rem; }

.mb-12 {
  margin-bottom: 3rem; }

.-mt-12 {
  margin-top: -3rem; }

.mt-12 {
  margin-top: 3rem; }

.ml-12 {
  margin-left: 3rem; }

.mr-12 {
  margin-right: 3rem; }

.m-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: 3rem;
  margin-right: 3rem; }

.f-12 {
  font-size: 12px; }

.font-weight-1200 {
  font-weight: 1200; }

.w-24 {
  width: 3rem; }

.h-24 {
  height: 3rem; }

.p-13 {
  padding-top: 3.25rem;
  padding-bottom: 3.25rem;
  padding-left: 3.25rem;
  padding-right: 3.25rem; }

.py-13 {
  padding-top: 3.25rem;
  padding-bottom: 3.25rem; }

.px-13 {
  padding-left: 3.25rem;
  padding-right: 3.25rem; }

.mx-13 {
  margin-left: 3.25rem;
  margin-right: 3.25rem; }

.my-13 {
  margin-top: 3.25rem;
  margin-bottom: 3.25rem; }

.-mb-13 {
  margin-bottom: -3.25rem; }

.mb-13 {
  margin-bottom: 3.25rem; }

.-mt-13 {
  margin-top: -3.25rem; }

.mt-13 {
  margin-top: 3.25rem; }

.ml-13 {
  margin-left: 3.25rem; }

.mr-13 {
  margin-right: 3.25rem; }

.m-13 {
  margin-top: 3.25rem;
  margin-bottom: 3.25rem;
  margin-left: 3.25rem;
  margin-right: 3.25rem; }

.font-weight-1300 {
  font-weight: 1300; }

.w-26 {
  width: 3.25rem; }

.h-26 {
  height: 3.25rem; }

.p-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  padding-left: 3.5rem;
  padding-right: 3.5rem; }

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem; }

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem; }

.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem; }

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem; }

.-mb-14 {
  margin-bottom: -3.5rem; }

.mb-14 {
  margin-bottom: 3.5rem; }

.-mt-14 {
  margin-top: -3.5rem; }

.mt-14 {
  margin-top: 3.5rem; }

.ml-14 {
  margin-left: 3.5rem; }

.mr-14 {
  margin-right: 3.5rem; }

.m-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  margin-left: 3.5rem;
  margin-right: 3.5rem; }

.f-14 {
  font-size: 14px; }

.font-weight-1400 {
  font-weight: 1400; }

.w-28 {
  width: 3.5rem; }

.h-28 {
  height: 3.5rem; }

.p-15 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  padding-left: 3.75rem;
  padding-right: 3.75rem; }

.py-15 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; }

.px-15 {
  padding-left: 3.75rem;
  padding-right: 3.75rem; }

.mx-15 {
  margin-left: 3.75rem;
  margin-right: 3.75rem; }

.my-15 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem; }

.-mb-15 {
  margin-bottom: -3.75rem; }

.mb-15 {
  margin-bottom: 3.75rem; }

.-mt-15 {
  margin-top: -3.75rem; }

.mt-15 {
  margin-top: 3.75rem; }

.ml-15 {
  margin-left: 3.75rem; }

.mr-15 {
  margin-right: 3.75rem; }

.m-15 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
  margin-left: 3.75rem;
  margin-right: 3.75rem; }

.font-weight-1500 {
  font-weight: 1500; }

.w-30 {
  width: 3.75rem; }

.h-30 {
  height: 3.75rem; }

.p-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 4rem;
  padding-right: 4rem; }

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem; }

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem; }

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.-mb-16 {
  margin-bottom: -4rem; }

.mb-16 {
  margin-bottom: 4rem; }

.-mt-16 {
  margin-top: -4rem; }

.mt-16 {
  margin-top: 4rem; }

.ml-16 {
  margin-left: 4rem; }

.mr-16 {
  margin-right: 4rem; }

.m-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-left: 4rem;
  margin-right: 4rem; }

.f-16 {
  font-size: 16px; }

.font-weight-1600 {
  font-weight: 1600; }

.w-32 {
  width: 4rem; }

.h-32 {
  height: 4rem; }

.p-17 {
  padding-top: 4.25rem;
  padding-bottom: 4.25rem;
  padding-left: 4.25rem;
  padding-right: 4.25rem; }

.py-17 {
  padding-top: 4.25rem;
  padding-bottom: 4.25rem; }

.px-17 {
  padding-left: 4.25rem;
  padding-right: 4.25rem; }

.mx-17 {
  margin-left: 4.25rem;
  margin-right: 4.25rem; }

.my-17 {
  margin-top: 4.25rem;
  margin-bottom: 4.25rem; }

.-mb-17 {
  margin-bottom: -4.25rem; }

.mb-17 {
  margin-bottom: 4.25rem; }

.-mt-17 {
  margin-top: -4.25rem; }

.mt-17 {
  margin-top: 4.25rem; }

.ml-17 {
  margin-left: 4.25rem; }

.mr-17 {
  margin-right: 4.25rem; }

.m-17 {
  margin-top: 4.25rem;
  margin-bottom: 4.25rem;
  margin-left: 4.25rem;
  margin-right: 4.25rem; }

.font-weight-1700 {
  font-weight: 1700; }

.w-34 {
  width: 4.25rem; }

.h-34 {
  height: 4.25rem; }

.p-18 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  padding-left: 4.5rem;
  padding-right: 4.5rem; }

.py-18 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem; }

.px-18 {
  padding-left: 4.5rem;
  padding-right: 4.5rem; }

.mx-18 {
  margin-left: 4.5rem;
  margin-right: 4.5rem; }

.my-18 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem; }

.-mb-18 {
  margin-bottom: -4.5rem; }

.mb-18 {
  margin-bottom: 4.5rem; }

.-mt-18 {
  margin-top: -4.5rem; }

.mt-18 {
  margin-top: 4.5rem; }

.ml-18 {
  margin-left: 4.5rem; }

.mr-18 {
  margin-right: 4.5rem; }

.m-18 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
  margin-left: 4.5rem;
  margin-right: 4.5rem; }

.f-18 {
  font-size: 18px; }

.font-weight-1800 {
  font-weight: 1800; }

.w-36 {
  width: 4.5rem; }

.h-36 {
  height: 4.5rem; }

.p-19 {
  padding-top: 4.75rem;
  padding-bottom: 4.75rem;
  padding-left: 4.75rem;
  padding-right: 4.75rem; }

.py-19 {
  padding-top: 4.75rem;
  padding-bottom: 4.75rem; }

.px-19 {
  padding-left: 4.75rem;
  padding-right: 4.75rem; }

.mx-19 {
  margin-left: 4.75rem;
  margin-right: 4.75rem; }

.my-19 {
  margin-top: 4.75rem;
  margin-bottom: 4.75rem; }

.-mb-19 {
  margin-bottom: -4.75rem; }

.mb-19 {
  margin-bottom: 4.75rem; }

.-mt-19 {
  margin-top: -4.75rem; }

.mt-19 {
  margin-top: 4.75rem; }

.ml-19 {
  margin-left: 4.75rem; }

.mr-19 {
  margin-right: 4.75rem; }

.m-19 {
  margin-top: 4.75rem;
  margin-bottom: 4.75rem;
  margin-left: 4.75rem;
  margin-right: 4.75rem; }

.font-weight-1900 {
  font-weight: 1900; }

.w-38 {
  width: 4.75rem; }

.h-38 {
  height: 4.75rem; }

.p-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 5rem;
  padding-right: 5rem; }

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem; }

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem; }

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem; }

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem; }

.-mb-20 {
  margin-bottom: -5rem; }

.mb-20 {
  margin-bottom: 5rem; }

.-mt-20 {
  margin-top: -5rem; }

.mt-20 {
  margin-top: 5rem; }

.ml-20 {
  margin-left: 5rem; }

.mr-20 {
  margin-right: 5rem; }

.m-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-left: 5rem;
  margin-right: 5rem; }

.f-20 {
  font-size: 20px; }

.font-weight-2000 {
  font-weight: 2000; }

.w-40 {
  width: 5rem; }

.h-40 {
  height: 5rem; }

.p-21 {
  padding-top: 5.25rem;
  padding-bottom: 5.25rem;
  padding-left: 5.25rem;
  padding-right: 5.25rem; }

.py-21 {
  padding-top: 5.25rem;
  padding-bottom: 5.25rem; }

.px-21 {
  padding-left: 5.25rem;
  padding-right: 5.25rem; }

.mx-21 {
  margin-left: 5.25rem;
  margin-right: 5.25rem; }

.my-21 {
  margin-top: 5.25rem;
  margin-bottom: 5.25rem; }

.-mb-21 {
  margin-bottom: -5.25rem; }

.mb-21 {
  margin-bottom: 5.25rem; }

.-mt-21 {
  margin-top: -5.25rem; }

.mt-21 {
  margin-top: 5.25rem; }

.ml-21 {
  margin-left: 5.25rem; }

.mr-21 {
  margin-right: 5.25rem; }

.m-21 {
  margin-top: 5.25rem;
  margin-bottom: 5.25rem;
  margin-left: 5.25rem;
  margin-right: 5.25rem; }

.font-weight-2100 {
  font-weight: 2100; }

.w-42 {
  width: 5.25rem; }

.h-42 {
  height: 5.25rem; }

.p-22 {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
  padding-left: 5.5rem;
  padding-right: 5.5rem; }

.py-22 {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem; }

.px-22 {
  padding-left: 5.5rem;
  padding-right: 5.5rem; }

.mx-22 {
  margin-left: 5.5rem;
  margin-right: 5.5rem; }

.my-22 {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem; }

.-mb-22 {
  margin-bottom: -5.5rem; }

.mb-22 {
  margin-bottom: 5.5rem; }

.-mt-22 {
  margin-top: -5.5rem; }

.mt-22 {
  margin-top: 5.5rem; }

.ml-22 {
  margin-left: 5.5rem; }

.mr-22 {
  margin-right: 5.5rem; }

.m-22 {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
  margin-left: 5.5rem;
  margin-right: 5.5rem; }

.f-22 {
  font-size: 22px; }

.font-weight-2200 {
  font-weight: 2200; }

.w-44 {
  width: 5.5rem; }

.h-44 {
  height: 5.5rem; }

.p-23 {
  padding-top: 5.75rem;
  padding-bottom: 5.75rem;
  padding-left: 5.75rem;
  padding-right: 5.75rem; }

.py-23 {
  padding-top: 5.75rem;
  padding-bottom: 5.75rem; }

.px-23 {
  padding-left: 5.75rem;
  padding-right: 5.75rem; }

.mx-23 {
  margin-left: 5.75rem;
  margin-right: 5.75rem; }

.my-23 {
  margin-top: 5.75rem;
  margin-bottom: 5.75rem; }

.-mb-23 {
  margin-bottom: -5.75rem; }

.mb-23 {
  margin-bottom: 5.75rem; }

.-mt-23 {
  margin-top: -5.75rem; }

.mt-23 {
  margin-top: 5.75rem; }

.ml-23 {
  margin-left: 5.75rem; }

.mr-23 {
  margin-right: 5.75rem; }

.m-23 {
  margin-top: 5.75rem;
  margin-bottom: 5.75rem;
  margin-left: 5.75rem;
  margin-right: 5.75rem; }

.font-weight-2300 {
  font-weight: 2300; }

.w-46 {
  width: 5.75rem; }

.h-46 {
  height: 5.75rem; }

.p-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
  padding-left: 6rem;
  padding-right: 6rem; }

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem; }

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem; }

.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem; }

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem; }

.-mb-24 {
  margin-bottom: -6rem; }

.mb-24 {
  margin-bottom: 6rem; }

.-mt-24 {
  margin-top: -6rem; }

.mt-24 {
  margin-top: 6rem; }

.ml-24 {
  margin-left: 6rem; }

.mr-24 {
  margin-right: 6rem; }

.m-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
  margin-left: 6rem;
  margin-right: 6rem; }

.f-24 {
  font-size: 24px; }

.font-weight-2400 {
  font-weight: 2400; }

.w-48 {
  width: 6rem; }

.h-48 {
  height: 6rem; }

.p-25 {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
  padding-left: 6.25rem;
  padding-right: 6.25rem; }

.py-25 {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem; }

.px-25 {
  padding-left: 6.25rem;
  padding-right: 6.25rem; }

.mx-25 {
  margin-left: 6.25rem;
  margin-right: 6.25rem; }

.my-25 {
  margin-top: 6.25rem;
  margin-bottom: 6.25rem; }

.-mb-25 {
  margin-bottom: -6.25rem; }

.mb-25 {
  margin-bottom: 6.25rem; }

.-mt-25 {
  margin-top: -6.25rem; }

.mt-25 {
  margin-top: 6.25rem; }

.ml-25 {
  margin-left: 6.25rem; }

.mr-25 {
  margin-right: 6.25rem; }

.m-25 {
  margin-top: 6.25rem;
  margin-bottom: 6.25rem;
  margin-left: 6.25rem;
  margin-right: 6.25rem; }

.font-weight-2500 {
  font-weight: 2500; }

.w-50 {
  width: 6.25rem; }

.h-50 {
  height: 6.25rem; }

.p-26 {
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;
  padding-left: 6.5rem;
  padding-right: 6.5rem; }

.py-26 {
  padding-top: 6.5rem;
  padding-bottom: 6.5rem; }

.px-26 {
  padding-left: 6.5rem;
  padding-right: 6.5rem; }

.mx-26 {
  margin-left: 6.5rem;
  margin-right: 6.5rem; }

.my-26 {
  margin-top: 6.5rem;
  margin-bottom: 6.5rem; }

.-mb-26 {
  margin-bottom: -6.5rem; }

.mb-26 {
  margin-bottom: 6.5rem; }

.-mt-26 {
  margin-top: -6.5rem; }

.mt-26 {
  margin-top: 6.5rem; }

.ml-26 {
  margin-left: 6.5rem; }

.mr-26 {
  margin-right: 6.5rem; }

.m-26 {
  margin-top: 6.5rem;
  margin-bottom: 6.5rem;
  margin-left: 6.5rem;
  margin-right: 6.5rem; }

.f-26 {
  font-size: 26px; }

.font-weight-2600 {
  font-weight: 2600; }

.w-52 {
  width: 6.5rem; }

.h-52 {
  height: 6.5rem; }

.p-27 {
  padding-top: 6.75rem;
  padding-bottom: 6.75rem;
  padding-left: 6.75rem;
  padding-right: 6.75rem; }

.py-27 {
  padding-top: 6.75rem;
  padding-bottom: 6.75rem; }

.px-27 {
  padding-left: 6.75rem;
  padding-right: 6.75rem; }

.mx-27 {
  margin-left: 6.75rem;
  margin-right: 6.75rem; }

.my-27 {
  margin-top: 6.75rem;
  margin-bottom: 6.75rem; }

.-mb-27 {
  margin-bottom: -6.75rem; }

.mb-27 {
  margin-bottom: 6.75rem; }

.-mt-27 {
  margin-top: -6.75rem; }

.mt-27 {
  margin-top: 6.75rem; }

.ml-27 {
  margin-left: 6.75rem; }

.mr-27 {
  margin-right: 6.75rem; }

.m-27 {
  margin-top: 6.75rem;
  margin-bottom: 6.75rem;
  margin-left: 6.75rem;
  margin-right: 6.75rem; }

.font-weight-2700 {
  font-weight: 2700; }

.w-54 {
  width: 6.75rem; }

.h-54 {
  height: 6.75rem; }

.p-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
  padding-left: 7rem;
  padding-right: 7rem; }

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem; }

.px-28 {
  padding-left: 7rem;
  padding-right: 7rem; }

.mx-28 {
  margin-left: 7rem;
  margin-right: 7rem; }

.my-28 {
  margin-top: 7rem;
  margin-bottom: 7rem; }

.-mb-28 {
  margin-bottom: -7rem; }

.mb-28 {
  margin-bottom: 7rem; }

.-mt-28 {
  margin-top: -7rem; }

.mt-28 {
  margin-top: 7rem; }

.ml-28 {
  margin-left: 7rem; }

.mr-28 {
  margin-right: 7rem; }

.m-28 {
  margin-top: 7rem;
  margin-bottom: 7rem;
  margin-left: 7rem;
  margin-right: 7rem; }

.f-28 {
  font-size: 28px; }

.font-weight-2800 {
  font-weight: 2800; }

.w-56 {
  width: 7rem; }

.h-56 {
  height: 7rem; }

.p-29 {
  padding-top: 7.25rem;
  padding-bottom: 7.25rem;
  padding-left: 7.25rem;
  padding-right: 7.25rem; }

.py-29 {
  padding-top: 7.25rem;
  padding-bottom: 7.25rem; }

.px-29 {
  padding-left: 7.25rem;
  padding-right: 7.25rem; }

.mx-29 {
  margin-left: 7.25rem;
  margin-right: 7.25rem; }

.my-29 {
  margin-top: 7.25rem;
  margin-bottom: 7.25rem; }

.-mb-29 {
  margin-bottom: -7.25rem; }

.mb-29 {
  margin-bottom: 7.25rem; }

.-mt-29 {
  margin-top: -7.25rem; }

.mt-29 {
  margin-top: 7.25rem; }

.ml-29 {
  margin-left: 7.25rem; }

.mr-29 {
  margin-right: 7.25rem; }

.m-29 {
  margin-top: 7.25rem;
  margin-bottom: 7.25rem;
  margin-left: 7.25rem;
  margin-right: 7.25rem; }

.font-weight-2900 {
  font-weight: 2900; }

.w-58 {
  width: 7.25rem; }

.h-58 {
  height: 7.25rem; }

.p-30 {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  padding-left: 7.5rem;
  padding-right: 7.5rem; }

.py-30 {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem; }

.px-30 {
  padding-left: 7.5rem;
  padding-right: 7.5rem; }

.mx-30 {
  margin-left: 7.5rem;
  margin-right: 7.5rem; }

.my-30 {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem; }

.-mb-30 {
  margin-bottom: -7.5rem; }

.mb-30 {
  margin-bottom: 7.5rem; }

.-mt-30 {
  margin-top: -7.5rem; }

.mt-30 {
  margin-top: 7.5rem; }

.ml-30 {
  margin-left: 7.5rem; }

.mr-30 {
  margin-right: 7.5rem; }

.m-30 {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
  margin-left: 7.5rem;
  margin-right: 7.5rem; }

.f-30 {
  font-size: 30px; }

.font-weight-3000 {
  font-weight: 3000; }

.w-60 {
  width: 7.5rem; }

.h-60 {
  height: 7.5rem; }

/* Quick class to convert cursor to be a pointer */
.k-cursor-pointer {
  cursor: pointer; }

.k-content-center {
  display: flex;
  justify-content: center;
  align-content: center; }

/* removes the border of an element */
.k-no-border {
  border: 0 !important; }

/* Applies 100% border-radius */
.k-rounded {
  border-radius: 100%; }

/* Applies 0.5rem border-radius */
.k-rounded-lg {
  border-radius: 0.5rem; }

/* A basic card with a shadow */
.k-card {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 8px; }

/* A larger card with a shadow */
.k-card-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); }

/* An outlined card */
.k-card-outline {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  border-radius: 8px; }

/* A card with no shadow */
.k-card-none {
  box-shadow: none;
  border-radius: 8px; }

/* Floats elements to the right */
.float-right {
  float: right; }

/* Floats elements to the left */
.float-left {
  float: left; }

/* underline text*/
.underline {
  text-decoration: underline; }

/* Screen sizes for different browsers */
/* Each row will fill 100% width and will use display flex*/
.k-row {
  display: flex;
  width: 100%; }

/* Add flex to allow rows to wrap if content fills the column */
.k-row-flex {
  flex-wrap: wrap; }

/* Applied on a row to center items horizontally and vertically */
.k-row-center-items {
  align-items: center;
  justify-content: center; }

/* If we want the column to have an auto width */
.k-col-auto {
  width: auto; }

/* Allows the column to fill the available space */
.k-col-grow {
  flex: 1; }

/* Allow the column to not grow, similar to width:auto */
.k-col-no-grow {
  flex: 0; }

/* This creates a grid system with 12 columns */
/* I used k-col to prevent collusions with future CSS-Framework grid system */
/* This is how it works 👇🏾 */
/* For each element of (i)  I use the flex-basis to get the percentage of the size of the column hence, (100/(12/$1))*/
/* A row will always have 12 columns and distributed accordingly for each column */
.k-col-1 {
  flex-basis: 8.33333%; }

.k-col-2 {
  flex-basis: 16.66667%; }

.k-col-3 {
  flex-basis: 25%; }

.k-col-4 {
  flex-basis: 33.33333%; }

.k-col-5 {
  flex-basis: 41.66667%; }

.k-col-6 {
  flex-basis: 50%; }

.k-col-7 {
  flex-basis: 58.33333%; }

.k-col-8 {
  flex-basis: 66.66667%; }

.k-col-9 {
  flex-basis: 75%; }

.k-col-10 {
  flex-basis: 83.33333%; }

.k-col-11 {
  flex-basis: 91.66667%; }

.k-col-12 {
  flex-basis: 100%; }

/* For a better responsive experience we have this for different screen sizes as created above */
/* We repeat this by following the format k-col-{screen}-{size} , screen sizes are defined above*/
/* small screen */
@media screen and (min-width: 576px) {
  .k-col-sm-1 {
    flex-basis: 8.33333%; }
  .k-col-sm-2 {
    flex-basis: 16.66667%; }
  .k-col-sm-3 {
    flex-basis: 25%; }
  .k-col-sm-4 {
    flex-basis: 33.33333%; }
  .k-col-sm-5 {
    flex-basis: 41.66667%; }
  .k-col-sm-6 {
    flex-basis: 50%; }
  .k-col-sm-7 {
    flex-basis: 58.33333%; }
  .k-col-sm-8 {
    flex-basis: 66.66667%; }
  .k-col-sm-9 {
    flex-basis: 75%; }
  .k-col-sm-10 {
    flex-basis: 83.33333%; }
  .k-col-sm-11 {
    flex-basis: 91.66667%; }
  .k-col-sm-12 {
    flex-basis: 100%; } }

/* medium screen */
@media screen and (min-width: 768px) {
  .k-col-md-1 {
    flex-basis: 8.33333%; }
  .k-col-md-2 {
    flex-basis: 16.66667%; }
  .k-col-md-3 {
    flex-basis: 25%; }
  .k-col-md-4 {
    flex-basis: 33.33333%; }
  .k-col-md-5 {
    flex-basis: 41.66667%; }
  .k-col-md-6 {
    flex-basis: 50%; }
  .k-col-md-7 {
    flex-basis: 58.33333%; }
  .k-col-md-8 {
    flex-basis: 66.66667%; }
  .k-col-md-9 {
    flex-basis: 75%; }
  .k-col-md-10 {
    flex-basis: 83.33333%; }
  .k-col-md-11 {
    flex-basis: 91.66667%; }
  .k-col-md-12 {
    flex-basis: 100%; } }

/* large screen */
@media screen and (min-width: 992px) {
  .k-col-lg-1 {
    flex-basis: 8.33333%; }
  .k-col-lg-2 {
    flex-basis: 16.66667%; }
  .k-col-lg-3 {
    flex-basis: 25%; }
  .k-col-lg-4 {
    flex-basis: 33.33333%; }
  .k-col-lg-5 {
    flex-basis: 41.66667%; }
  .k-col-lg-6 {
    flex-basis: 50%; }
  .k-col-lg-7 {
    flex-basis: 58.33333%; }
  .k-col-lg-8 {
    flex-basis: 66.66667%; }
  .k-col-lg-9 {
    flex-basis: 75%; }
  .k-col-lg-10 {
    flex-basis: 83.33333%; }
  .k-col-lg-11 {
    flex-basis: 91.66667%; }
  .k-col-lg-12 {
    flex-basis: 100%; } }

/* extra-large screen */
@media screen and (min-width: 1200px) {
  .k-col-xl-1 {
    flex-basis: 8.33333%; }
  .k-col-xl-2 {
    flex-basis: 16.66667%; }
  .k-col-xl-3 {
    flex-basis: 25%; }
  .k-col-xl-4 {
    flex-basis: 33.33333%; }
  .k-col-xl-5 {
    flex-basis: 41.66667%; }
  .k-col-xl-6 {
    flex-basis: 50%; }
  .k-col-xl-7 {
    flex-basis: 58.33333%; }
  .k-col-xl-8 {
    flex-basis: 66.66667%; }
  .k-col-xl-9 {
    flex-basis: 75%; }
  .k-col-xl-10 {
    flex-basis: 83.33333%; }
  .k-col-xl-11 {
    flex-basis: 91.66667%; }
  .k-col-xl-12 {
    flex-basis: 100%; } }

.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 20rem;
  margin: auto;
  margin-top: 1rem; }
  .form .MuiInputBase-input {
    height: 0.8rem; }
  .form .MuiOutlinedInput-input {
    padding: 18.5px 14px;
    font-size: 18px; }
  .form .MuiTypography-body1 {
    font-size: 0.8rem;
    font-weight: 100; }
  .form .form-header {
    font-size: 1.2rem;
    font-weight: 200;
    text-align: left; }
  .form .text-input {
    width: 20rem;
    height: 3.75rem;
    margin-top: 1rem; }
  .form .form-helper-btns {
    display: flex;
    justify-content: space-between;
    font-weight: 100;
    font-size: 13px;
    margin-top: 0.5rem;
    cursor: pointer; }
    .form .form-helper-btns .forgot-pswd {
      margin-top: 0.625rem;
      cursor: pointer; }
  .form .MuiFormLabel-root {
    font-size: 0.8rem; }
    .form .MuiFormLabel-root.Mui-focused {
      color: #387C3F; }
  .form .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #387C3F; }
  .form .MuiCheckbox-colorPrimary.Mui-checked {
    color: #387C3F; }
  .form .MuiFormHelperText-contained {
    margin-right: 0px;
    margin-bottom: 0px; }
  .form .MuiFormHelperText-root {
    color: #F71202;
    margin-left: 5px;
    font-size: 0.75rem; }

.main-title-text {
  font-size: 25px;
  font-weight: 600;
  color: #3E3E3E; }

.main-subtitle-text {
  color: #8F8F8F;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase; }

.spinner-container {
  text-align: center; }
  .spinner-container .spinner {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    width: 2em;
    height: 2em; }
    .spinner-container .spinner .path {
      stroke: #387C3F;
      stroke-linecap: round;
      -webkit-animation: dash 1.5s ease-in-out infinite;
      animation: dash 1.5s ease-in-out infinite; }
    .spinner-container .spinner.charcoal .path {
      stroke: #3E3E3E; }
    .spinner-container .spinner.white .path {
      stroke: #FFF; }

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124; } }

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124; } }

.footer-list {
  width: 9.25rem; }
  .footer-list .list-title {
    font-size: 0.87rem; }
  .footer-list ul {
    padding-inline-start: 0;
    line-height: 11px; }
  .footer-list li {
    font-size: 0.6rem;
    font-weight: 100;
    list-style-type: none; }

.btn {
  color: #FFF;
  border-radius: 0.5rem;
  font-size: 1rem;
  text-align: center;
  color: #FFF;
  height: 3.2rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .btn .btn-title {
    cursor: pointer; }
  .btn.greybg {
    background-color: #C7C7C7;
    cursor: not-allowed; }
    .btn.greybg .btn-title {
      cursor: not-allowed; }
  .btn.greenbg {
    background-color: #387C3F;
    cursor: pointer; }
    .btn.greenbg .btn-title {
      cursor: pointer; }

.modal-container {
  display: block;
  position: fixed;
  z-index: 105;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column; }
  .modal-container .modal-filler {
    flex: 1; }
  .modal-container .modal-middle-filler {
    display: flex;
    flex-direction: row; }
  .modal-container .modal-content {
    background-color: #FFF;
    margin: auto;
    padding: 40px;
    width: 700px;
    border-radius: 5px;
    position: relative; }
  .modal-container .close-ic {
    display: flex;
    position: absolute;
    right: 2.5rem;
    top: -20px;
    background-color: #FFF;
    box-shadow: 0px 1px 6px #00000029;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    align-items: center;
    cursor: pointer;
    justify-content: center; }

@media (max-width: 768px) {
  .modal-container .modal-content {
    width: 12rem;
    padding: 15px; }
  .modal-container .close-ic {
    right: 1.5rem;
    top: -12px;
    width: 25px;
    height: 25px;
    font-size: 0.6rem; } }

.loading-screen {
  height: 100vh;
  width: 100%; }

.header-container {
  background-color: #FFF;
  font-size: 12px;
  color: #8F8F8F;
  position: sticky;
  top: 0;
  z-index: 100;
  height: 5rem; }
  .header-container .header-contents {
    width: 100%;
    display: flex;
    justify-content: space-between; }
    .header-container .header-contents .container {
      display: inline-block;
      cursor: pointer;
      margin-right: 30px;
      margin-top: 5px; }
    .header-container .header-contents .bar1, .header-container .header-contents .bar2, .header-container .header-contents .bar3 {
      width: 30px;
      height: 3px;
      background-color: #387C3F;
      margin: 6px 0;
      transition: 0.4s;
      border-radius: 10px; }
    .header-container .header-contents .change .bar1 {
      -webkit-transform: rotate(-45deg) translate(-8px, 9px);
      transform: rotate(-45deg) translate(-8px, 9px); }
    .header-container .header-contents .change .bar2 {
      opacity: 0; }
    .header-container .header-contents .change .bar3 {
      -webkit-transform: rotate(45deg) translate(-3px, -5px);
      transform: rotate(45deg) translate(-3px, -5px); }
    .header-container .header-contents .show-menu {
      box-shadow: 1px 0px 3px #4c4c4b80;
      border-radius: 3px;
      position: absolute;
      right: 10px;
      height: fit-content;
      display: block;
      background-color: #FFF; }
      .header-container .header-contents .show-menu .nav-dropdown-title {
        padding: 5px 10px;
        border-bottom: solid 1px #c7c7c7; }
        .header-container .header-contents .show-menu .nav-dropdown-title a {
          color: #8F8F8F;
          text-decoration: none; }
        .header-container .header-contents .show-menu .nav-dropdown-title:last-child {
          border-bottom: none; }
    .header-container .header-contents .hide-menu {
      display: none; }
    .header-container .header-contents .header-left {
      width: 22%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center; }
      .header-container .header-contents .header-left .logo {
        margin: 16px 0; }
        .header-container .header-contents .header-left .logo img {
          width: 100px; }
    .header-container .header-contents .navbar-links {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 50px; }
      .header-container .header-contents .navbar-links .action-call {
        width: fit-content;
        margin-right: 65px; }
        .header-container .header-contents .navbar-links .action-call .nav-btn {
          background-color: #387C3F;
          border: 1px solid #387C3F;
          margin: 0;
          height: 35px;
          font-size: 10px;
          padding: 0 16px;
          margin-left: 30px; }
          .header-container .header-contents .navbar-links .action-call .nav-btn a {
            color: inherit;
            text-decoration: none;
            height: 35px;
            display: flex;
            flex-direction: row;
            align-items: center; }
          .header-container .header-contents .navbar-links .action-call .nav-btn span {
            margin-left: 10px;
            font-weight: bold; }
          .header-container .header-contents .navbar-links .action-call .nav-btn .emoji {
            font-size: 18px;
            margin-right: 5px;
            margin-left: unset; }
        .header-container .header-contents .navbar-links .action-call .secondary {
          background-color: #FFF;
          color: #387C3F;
          border: solid 1px #387C3F; }
      .header-container .header-contents .navbar-links .link {
        height: 1rem;
        margin: 0 20px; }
        .header-container .header-contents .navbar-links .link .nav-dropdown-title {
          height: 1rem;
          display: flex;
          align-items: flex-end;
          cursor: pointer;
          color: #8f8f8f;
          text-decoration: none; }
        .header-container .header-contents .navbar-links .link .nav-dropdown {
          background-color: #F7F7F7;
          box-shadow: 0px 4px 4px 0px rgba(61, 61, 61, 0.5);
          height: 0rem;
          border-radius: 0px 0px 5px 5px;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          overflow: hidden;
          position: absolute; }
          .header-container .header-contents .navbar-links .link .nav-dropdown .nav-dropdown-item {
            color: #3E3E3E;
            text-decoration: none;
            display: flex;
            height: 2rem;
            align-items: center;
            padding: 2px 10px; }
            .header-container .header-contents .navbar-links .link .nav-dropdown .nav-dropdown-item:hover {
              color: #387C3F;
              text-decoration: none;
              background-color: rgba(172, 231, 204, 0.28); }
          .header-container .header-contents .navbar-links .link .nav-dropdown .item-icon {
            margin-left: 5px; }
          .header-container .header-contents .navbar-links .link .nav-dropdown.open {
            height: 5rem; }
  @media only screen and (min-width: 1441px) {
    .header-container .header-contents {
      max-width: 1440px;
      margin: auto; } }

@media (max-width: 768px) {
  .header-container {
    height: 3rem;
    position: fixed;
    right: 0;
    left: 0; }
    .header-container .header-contents {
      margin: 0 5%; }
      .header-container .header-contents .header-left .logo {
        margin: 16px 0; }
        .header-container .header-contents .header-left .logo img {
          width: 90%; }
      .header-container .header-contents .navbar-links {
        padding-right: 0px; }
        .header-container .header-contents .navbar-links .link {
          height: 1rem; } }

.footer-screen {
  min-height: 250px !important;
  flex-direction: row;
  align-items: center;
  margin: 20px 40px;
  justify-content: space-between;
  display: flex; }
  .footer-screen .logo img {
    width: 50px; }
  .footer-screen .s-header {
    color: #3E3E3E;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px; }
  .footer-screen .contact {
    color: #3E3E3E;
    margin: 20px;
    font-size: 14px;
    font-weight: 100;
    line-height: 26px; }
    .footer-screen .contact span {
      margin-right: 10px; }
  .footer-screen .social-media {
    height: 78px;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 40px; }
  .footer-screen .media-links {
    font-size: 20px;
    display: flex;
    color: #3E3E3E;
    width: 150px;
    justify-content: space-between; }
    .footer-screen .media-links a {
      color: #3E3E3E;
      text-decoration: none; }
    .footer-screen .media-links a:hover {
      color: #3E3E3E;
      text-decoration: none; }
    .footer-screen .media-links a:visited {
      color: #3E3E3E;
      text-decoration: none; }
  .footer-screen .document-links {
    font-size: 12px;
    display: flex;
    width: 200px;
    flex-direction: column; }
    .footer-screen .document-links a {
      color: #3E3E3E;
      text-decoration: none; }
    .footer-screen .document-links a:hover {
      color: #A4C4A5;
      text-decoration: none; }
    .footer-screen .document-links a:visited {
      color: #387C3F;
      text-decoration: none; }
  .footer-screen .copyright {
    font-size: 12px;
    color: #646464;
    margin-top: 20px; }

@media (max-width: 768px) {
  .footer-screen {
    flex-direction: column; }
    .footer-screen .logo {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .footer-screen .logo img {
        width: 100px; }
    .footer-screen .copyright {
      font-size: 7px; }
    .footer-screen .contact {
      width: 200px; }
    .footer-screen .media-links {
      width: 70%;
      justify-content: space-around; }
      .footer-screen .media-links a {
        margin: 0 5px; } }

.section-navigation {
  height: 15rem; }
  .section-navigation .nav-section {
    width: 20%;
    margin: 0 20px 20px;
    color: #5c5c5c; }
    .section-navigation .nav-section .primary-txt {
      font-size: 28px;
      font-weight: 600;
      width: 80%;
      line-height: 34px; }
    .section-navigation .nav-section .secondary-txt {
      color: #8F8F8F;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 12px; }
    .section-navigation .nav-section .nav-section-header {
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 10px; }
    .section-navigation .nav-section .nav-section-txt {
      font-size: 12px;
      color: #3e3e3e;
      width: 50%;
      font-weight: 300;
      margin-bottom: 14px; }
    .section-navigation .nav-section .nav-btn {
      font-weight: 500;
      border-bottom: solid #387C3F;
      padding-bottom: 5px;
      width: 60px;
      display: flex;
      justify-content: space-between;
      cursor: pointer; }

@media (max-width: 768px) {
  .section-navigation {
    display: block;
    height: 11rem; }
    .section-navigation .nav-section {
      float: left;
      width: 100%;
      margin-bottom: 40px; }
      .section-navigation .nav-section .primary-txt {
        width: 100%;
        font-size: 22px; }
      .section-navigation .nav-section .secondary-txt {
        margin-top: 20px; }
      .section-navigation .nav-section .nav-btn {
        margin: auto;
        width: 80px; }
    .section-navigation .nav-section-mobile {
      float: left;
      width: 20%;
      margin: 0 10px 10px 20px; } }

.landing-screen {
  height: calc(100vh - 5rem - 15rem);
  align-items: center;
  display: flex;
  width: 100%;
  overflow: hidden; }
  .landing-screen .left-section {
    margin-left: 150px;
    width: 550px; }
    .landing-screen .left-section img {
      width: 300px; }
    .landing-screen .left-section .company-name {
      color: #8F8F8F;
      font-size: 12px;
      margin: 10px 0; }
    .landing-screen .left-section .company-description {
      font-size: 14px;
      color: #3E3E3E;
      width: 300px;
      line-height: 24px; }
  .landing-screen .right-section {
    position: relative;
    height: calc(1000px * 0.31825); }
    .landing-screen .right-section img {
      width: 1000px;
      left: calc(calc(100vh - 1000px)/2 - 700px);
      position: absolute;
      -webkit-transition: all 1.8s ease-in-out;
      -moz-transition: all 1.8s ease-in-out;
      -ms-transition: all 1.8s ease-in-out;
      -o-transition: all 1.8s ease-in-out;
      transition: all 1.8s ease-in-out; }
      .landing-screen .right-section img.animate {
        left: -150px; }

@media (max-width: 768px) {
  .landing-screen {
    height: unset;
    min-height: calc(100vh - 3rem - 11rem);
    padding-top: 3rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start; }
    .landing-screen .left-section {
      margin-left: 60px;
      width: unset;
      text-align: left;
      margin-bottom: 20px;
      margin-right: 20px; }
      .landing-screen .left-section img {
        width: 200px; }
      .landing-screen .left-section .company-name {
        color: #8F8F8F;
        font-size: 14px;
        margin: 10px 0; }
      .landing-screen .left-section .company-description {
        font-size: 16px;
        color: #3E3E3E;
        width: 300px;
        line-height: 24px;
        letter-spacing: 1px;
        padding-bottom: 30px; }
    .landing-screen .right-section {
      height: calc(450px * 0.31825);
      width: 100%;
      padding-top: 100px;
      padding-bottom: 60px; }
      .landing-screen .right-section img {
        width: 450px;
        left: calc(-2 * 450px / 3);
        position: absolute; }
        .landing-screen .right-section img.animate {
          left: -30px; } }

@media only screen and (min-width: 1441px) {
  .landing-screen {
    margin: 0;
    max-width: 1440px;
    margin: auto;
    overflow: unset; } }

.launch-video-container {
  flex-direction: column; }
  .launch-video-container .title {
    margin: 40px 0;
    width: 20%; }

@media (max-width: 768px) {
  .title {
    width: 100% !important; } }

.stakeholders-screen {
  flex-direction: column; }
  .stakeholders-screen .stakeholder-logos {
    justify-content: space-around;
    width: 85%;
    margin: 50px 0;
    text-align: center; }
    .stakeholders-screen .stakeholder-logos img {
      height: 60px;
      margin: 10px; }

@media (max-width: 768px) {
  .stakeholders-screen .stakeholder-logos {
    width: 95%;
    display: block;
    margin: 0 auto;
    text-align: center; }
    .stakeholders-screen .stakeholder-logos img {
      margin: 10px;
      height: 40px; } }

.products-screen {
  position: relative;
  overflow: hidden; }
  .products-screen .details-container .section {
    padding-bottom: 150px; }
  .products-screen .details-container .left-section {
    flex: 2;
    margin-right: 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    .products-screen .details-container .left-section .left-section-text {
      width: 66%; }
    .products-screen .details-container .left-section .section-name {
      color: #C7C7C7;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 16px; }
    .products-screen .details-container .left-section .header-txt {
      color: #FFF;
      opacity: .9; }
      .products-screen .details-container .left-section .header-txt.h1 {
        font-size: 55px;
        font-weight: 600;
        margin-bottom: 35px; }
      .products-screen .details-container .left-section .header-txt.h2 {
        font-size: 30px;
        font-weight: 600; }
      .products-screen .details-container .left-section .header-txt.h3 {
        opacity: .8;
        margin-bottom: 10px;
        font-size: 18px;
        color: #e8ffe8; }
    .products-screen .details-container .left-section .left-section-txt {
      font-weight: 300;
      line-height: 21px;
      color: #a7d4a8;
      font-size: 15px; }
      .products-screen .details-container .left-section .left-section-txt .farmer-product-name {
        font-weight: 600;
        color: white;
        opacity: .9; }
  .products-screen .details-container .input-app-container {
    height: 600px;
    position: relative;
    flex: 1;
    padding-top: 50px; }
    .products-screen .details-container .input-app-container .app-container {
      position: absolute;
      height: 600px;
      z-index: 10;
      width: 270px;
      overflow: hidden; }
    .products-screen .details-container .input-app-container .app-bezel {
      position: absolute;
      height: 600px;
      z-index: 10;
      width: 270px; }
    .products-screen .details-container .input-app-container .input-app {
      height: 600px;
      position: absolute;
      z-index: 8;
      transition: all 1.8s, opacity .3s; }
    .products-screen .details-container .input-app-container .input-app-visible-center {
      right: 50%;
      transform: translate(50%, 0%); }
    .products-screen .details-container .input-app-container .input-app-hidden-left {
      right: 100%; }
    .products-screen .details-container .input-app-container .trader-app-vissible-center {
      left: 50%;
      transform: translate(-50%, 0%); }
    .products-screen .details-container .input-app-container .trader-app-hidden-right {
      left: 100%; }
  .products-screen .details-container .right-section {
    flex: 2;
    margin-left: 35px;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
    .products-screen .details-container .right-section .arrow-keys {
      width: 400px;
      color: #FFF;
      display: flex;
      justify-content: space-between; }
    .products-screen .details-container .right-section .slides-container {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 400px;
      height: 125px;
      margin-bottom: 20px; }
      .products-screen .details-container .right-section .slides-container .slide-container {
        position: absolute;
        display: block;
        min-width: 100%;
        transition: all 1.3s, opacity .2s; }
        .products-screen .details-container .right-section .slides-container .slide-container .heading-container {
          color: #FFF;
          font-weight: 600;
          font-size: 23px;
          opacity: .9;
          margin: 0 0 25px 0px; }
        .products-screen .details-container .right-section .slides-container .slide-container .description-container {
          font-weight: 300;
          line-height: 23px;
          color: #cfe4cf;
          font-size: 14px;
          width: 80%; }
    .products-screen .details-container .right-section .action-btns {
      cursor: pointer;
      bottom: 25%;
      transform: translate(0%, 50%);
      position: absolute; }
      .products-screen .details-container .right-section .action-btns .white-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        padding: 10px 15px;
        border-radius: 8px;
        font-size: 12px;
        color: green;
        font-weight: 500;
        text-align: center; }
      .products-screen .details-container .right-section .action-btns .transparent-btn {
        font-size: 10px;
        padding: 10px 15px;
        color: white; }
        .products-screen .details-container .right-section .action-btns .transparent-btn span {
          margin-left: 10px; }
  .products-screen .section-color {
    height: 200px;
    width: 100%;
    margin-top: -150px;
    background-color: #EBF2EB; }

@media (max-width: 768px) {
  .products-screen {
    height: 600px; }
    .products-screen .section-color {
      display: none; }
    .products-screen .header-txt {
      color: #FFF;
      opacity: .9; }
      .products-screen .header-txt.h1 {
        font-size: 30px;
        font-weight: 600;
        margin: 20px 0 10px 30px; }
      .products-screen .header-txt.h3 {
        opacity: .8;
        margin-bottom: 12px;
        font-size: 14px;
        color: #e8ffe8;
        margin-left: 30px; }
    .products-screen .details-container .right-section {
      margin-left: 0;
      margin-right: 10px;
      height: 240px;
      flex: 1; }
      .products-screen .details-container .right-section .slides-container {
        width: unset; }
      .products-screen .details-container .right-section .arrow-keys {
        width: unset; }
      .products-screen .details-container .right-section .action-btns .white-btn {
        font-size: 10px; }
    .products-screen .details-container .input-app-container {
      padding-top: 20px;
      width: calc(400px * (1 / 2.22222));
      margin-left: -70px; }
      .products-screen .details-container .input-app-container .app-container {
        margin-left: 0px;
        height: 400px;
        width: calc(400px * (1 / 2.22222)); }
      .products-screen .details-container .input-app-container .input-app {
        height: 100%;
        width: 100%; }
      .products-screen .details-container .input-app-container .app-bezel {
        width: 100%;
        height: 100%; }
      .products-screen .details-container .input-app-container .input-app-visible-center {
        width: 100%;
        height: 100%; }
      .products-screen .details-container .input-app-container .trader-app-hidden-right {
        width: 100%;
        height: 100%; } }

.inputs-market-screen {
  flex-direction: column;
  padding-top: 50px; }
  .inputs-market-screen .section {
    justify-content: center;
    width: 65%;
    margin: 50px 0; }
    .inputs-market-screen .section .section-graphic {
      margin-right: 100px; }
      .inputs-market-screen .section .section-graphic img {
        width: 400px; }
    .inputs-market-screen .section .right-section .bullet-point {
      margin: 15px 0 30px; }
      .inputs-market-screen .section .right-section .bullet-point .bullet-header {
        font-weight: 500;
        font-size: 20px; }
        .inputs-market-screen .section .right-section .bullet-point .bullet-header span {
          color: #387C3F;
          font-size: 24px;
          margin-right: 10px; }
      .inputs-market-screen .section .right-section .bullet-point .bullet-text {
        color: #646464;
        font-size: 16px;
        line-height: 28px;
        margin: 15px 0; }

@media (max-width: 768px) {
  .inputs-market-screen .section {
    display: block;
    margin: 20px 0; }
    .inputs-market-screen .section .right-section {
      float: left; }
      .inputs-market-screen .section .right-section .bullet-point {
        text-align: center; }
        .inputs-market-screen .section .right-section .bullet-point .bullet-header {
          font-size: 18px !important;
          display: flex;
          flex-direction: column;
          width: 75%;
          margin: auto; }
          .inputs-market-screen .section .right-section .bullet-point .bullet-header span {
            margin-right: 0px;
            margin-bottom: 10px; }
      .inputs-market-screen .section .right-section .bullet-text {
        font-size: 13px !important; }
  .inputs-market-screen .section-graphic {
    float: left; }
    .inputs-market-screen .section-graphic img {
      width: 250px !important; } }

.produce-market-screen {
  flex-direction: column;
  padding: 50px 0; }
  .produce-market-screen .row .left-section {
    margin-left: 120px; }
  .produce-market-screen .row .title {
    text-align: start;
    width: 60%; }
  .produce-market-screen .row .sub-title {
    color: #387C3F; }
  .produce-market-screen .row .section-text {
    color: #646464;
    width: 60%;
    line-height: 26px;
    margin: 20px 0; }
  .produce-market-screen .row .action-btns {
    width: 40%;
    justify-content: space-between; }
    .produce-market-screen .row .action-btns .green-btn {
      background-color: #387C3F;
      padding: 12px 20px;
      border-radius: 8px;
      font-size: 12px;
      color: white;
      width: fit-content; }
    .produce-market-screen .row .action-btns .transparent-btn {
      border-bottom: solid #387C3F;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px; }
      .produce-market-screen .row .action-btns .transparent-btn span {
        font-weight: 800;
        margin-left: 10px; }
  .produce-market-screen .row img {
    width: 600px; }

@media (max-width: 768px) {
  .produce-market-screen .row .left-section {
    margin-left: 20px; }
  .produce-market-screen .row .sub-title {
    text-align: left;
    margin-left: 0; }
  .produce-market-screen .row .section-text {
    width: 100%;
    font-size: 12px; }
  .produce-market-screen .row .web {
    width: 200px;
    left: 175px; } }

.fresh-produce-benefits-screen {
  flex-direction: column;
  padding: 30px 0; }
  .fresh-produce-benefits-screen .main-section {
    margin: 50px 0; }
    .fresh-produce-benefits-screen .main-section .detail-container {
      flex: 1; }
      .fresh-produce-benefits-screen .main-section .detail-container .icon-container {
        background-color: #A4C4A5;
        width: 80px;
        height: 80px;
        border-radius: 100%; }
        .fresh-produce-benefits-screen .main-section .detail-container .icon-container .icon {
          color: #387C3F;
          font-size: 28px; }
      .fresh-produce-benefits-screen .main-section .detail-container .detail-text {
        font-size: 14px;
        text-align: center;
        line-height: 1.8;
        width: 80%; }
    .fresh-produce-benefits-screen .main-section .arrow {
      width: 40px;
      margin: 0 20px; }
      .fresh-produce-benefits-screen .main-section .arrow div {
        width: 1px;
        background-color: #C7C7C7;
        height: 80px; }
      .fresh-produce-benefits-screen .main-section .arrow .arrow-top {
        transform: rotate(-8deg) translate(20px, 4px); }
      .fresh-produce-benefits-screen .main-section .arrow .arrow-bottom {
        transform: rotate(8deg) translate(20px, -4px); }

@media (max-width: 768px) {
  .fresh-produce-benefits-screen {
    padding: 20px 0; }
    .fresh-produce-benefits-screen .main-section {
      display: block;
      margin: 20px 0; }
      .fresh-produce-benefits-screen .main-section .detail-container {
        display: block;
        margin-bottom: 35px; }
        .fresh-produce-benefits-screen .main-section .detail-container .icon-container {
          float: left;
          margin-top: 20px;
          height: 70px;
          width: 70px;
          margin-left: 20px; }
        .fresh-produce-benefits-screen .main-section .detail-container .header {
          margin-left: 100px;
          margin-bottom: 10px; }
        .fresh-produce-benefits-screen .main-section .detail-container .detail-text {
          margin-left: 100px;
          text-align: left;
          font-size: 12px;
          width: 60%; }
      .fresh-produce-benefits-screen .main-section .arrow {
        display: none; } }

.testimonies-screen {
  height: calc(100vh - 5rem);
  max-height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #001d01;
  padding-top: calc(100vh * .08); }
  .testimonies-screen *t {
    border: 1px solid white; }
  .testimonies-screen .header-text {
    color: white;
    font-weight: 600; }
  .testimonies-screen .first-line-text {
    opacity: .6;
    font-size: 12.5px;
    margin-bottom: 1%; }
  .testimonies-screen .title-line-text {
    margin-bottom: 3%;
    text-align: center;
    width: 30%;
    font-size: 24px; }
  .testimonies-screen .tesimonies-container {
    position: relative;
    width: 55%;
    height: calc(100vh * .55);
    overflow: hidden; }

.testimonial-item-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row; }
  .testimonial-item-container .testimonial-left-panel {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .testimonial-item-container .testimonial-left-panel .quoatation-marks {
      font-size: 28px;
      font-style: italic;
      color: #7cee3a;
      opacity: .8; }
    .testimonial-item-container .testimonial-left-panel .testimonial-details {
      width: 90%;
      color: white;
      font-weight: 600;
      opacity: .9; }
    .testimonial-item-container .testimonial-left-panel .testimonial-message {
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 12%; }
    .testimonial-item-container .testimonial-left-panel .testimonial-name {
      font-size: 14px;
      margin-bottom: 2%;
      color: #7cee3a; }
    .testimonial-item-container .testimonial-left-panel .testimonial-position {
      font-size: 12px;
      opacity: .8; }
  .testimonial-item-container .testimonial-right-panel {
    width: 50%; }
    .testimonial-item-container .testimonial-right-panel .testimonial-image {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover; }

@media (max-width: 768px) {
  .testimonies-screen {
    height: 550px;
    padding-bottom: calc(100vh * .08); }
    .testimonies-screen .title-line-text {
      width: 90%; }
    .testimonies-screen .first-line-text {
      margin-bottom: 20px; }
    .testimonies-screen .tesimonies-container {
      width: 80%; }
  .testimonial-item-container .testimonial-left-panel .quoatation-marks {
    font-size: 16px; }
  .testimonial-item-container .testimonial-left-panel .testimonial-message {
    font-size: 10px;
    line-height: 16px;
    font-weight: 400; }
  .testimonial-item-container .testimonial-left-panel .testimonial-name {
    font-size: 10px; }
  .testimonial-item-container .testimonial-left-panel .testimonial-position {
    font-size: 9px; } }

.contact-screen .section {
  align-items: center;
  padding: 30px;
  justify-content: center; }
  .contact-screen .section .section-graphic img {
    width: 300px;
    border-radius: 5px;
    box-shadow: 4px 6px 11px 6px #00000029; }
  .contact-screen .section .right-section {
    margin-left: 50px;
    width: 40%; }
    .contact-screen .section .right-section .section-header {
      font-size: 30px; }
    .contact-screen .section .right-section .section-txt {
      line-height: 30px;
      font-weight: 100;
      margin: 30px 0; }
    .contact-screen .section .right-section .white-btn {
      font-weight: 600;
      padding: 15px 30px;
      font-size: 12px;
      background-color: #387C3F;
      color: #FFF; }

@media (max-width: 768px) {
  .contact-screen .section {
    margin-left: 10px;
    width: 90%;
    display: block; }
    .contact-screen .section .right-section {
      width: 100%;
      margin-top: 20px;
      margin-left: 0; }
      .contact-screen .section .right-section .section-header {
        font-size: 26px;
        text-align: center; }
      .contact-screen .section .right-section .white-btn {
        margin: auto; } }

.carousel-button-container {
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s; }

.pulse-animation {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 1; }

.pulse-one {
  animation: pulse-one .8s infinite alternate; }

.pulse-two {
  width: 31px;
  height: 31px;
  border: 1px solid #669745;
  border-radius: 50%;
  animation: pulse-two .8s infinite alternate; }

.toggle-icon-container {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(134, 241, 72, 0.514); }
  .toggle-icon-container .icon {
    font-size: 18px;
    color: white;
    opacity: .9; }

@keyframes pulse-one {
  0% {
    transform: scale(1, 1); }
  25% {
    transform: scale(1.4, 1.4); }
  60% {
    transform: scale(1.1, 1.1); }
  100% {
    transform: scale(1.3, 1.3);
    background-color: #ffffff52;
    opacity: .3; } }

@keyframes pulse-two {
  0% {
    transform: scale(1, 1);
    opacity: .5; }
  25% {
    transform: scale(1.5, 1.5); }
  60% {
    transform: scale(1.2, 1.2); }
  100% {
    transform: scale(1.4, 1.4);
    opacity: .3; } }

.google-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh; }
  .google-form-container .iframe {
    border-width: unset;
    border-style: unset;
    height: 100vh;
    width: 100%; }

@media (max-width: 768px) {
  .google-form-container {
    height: calc(100vh - 3rem);
    margin-top: 3rem; } }

.blog-page-title {
  font-size: 50px;
  font-weight: 600;
  color: #3E3E3E;
  margin-left: 130px;
  margin-top: 30px;
  display: flex; }

.blog-page-sub-title {
  font-size: 24px;
  font-weight: 500;
  color: #3E3E3E;
  display: flex;
  margin-left: 130px;
  margin-top: 10px; }

.loader {
  height: 400px;
  margin: 50px 0; }

.article-tiles-container {
  margin: auto;
  width: 85vw; }
  .article-tiles-container .article-tile {
    margin: 20px;
    width: 30%;
    height: 400px;
    overflow: hidden;
    float: left;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
    color: #3E3E3E !important; }
    .article-tiles-container .article-tile .article-image {
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 5px 5px 0 0;
      position: relative;
      width: 100%;
      height: 200px; }
    .article-tiles-container .article-tile .tile-copy {
      margin: 15px;
      font-weight: 500;
      font-size: 12px; }
      .article-tiles-container .article-tile .tile-copy .article-citation {
        font-size: 11px;
        display: flex;
        justify-content: space-between;
        font-weight: 100; }
      .article-tiles-container .article-tile .tile-copy .article-title {
        margin: 15px 0;
        font-size: 18px; }
      .article-tiles-container .article-tile .tile-copy .article-subtitle {
        font-size: 16px; }
      .article-tiles-container .article-tile .tile-copy .article-preview {
        line-height: 20px;
        margin-bottom: 20px; }
  .article-tiles-container .article-tile:hover {
    box-shadow: 0px 0px 20px #4A794680; }

@media (max-width: 768px) {
  .blog-page-title {
    font-size: 30px;
    margin-top: 70px;
    margin-left: 18px; }
  .blog-page-sub-title {
    margin-left: 18px;
    font-size: 16px; }
  .article-tiles-container {
    margin: 30px auto; }
    .article-tiles-container .article-tile {
      margin: 10px;
      width: 90%;
      height: 370px; }
      .article-tiles-container .article-tile .article-image {
        height: 190px; } }

.blog-post {
  color: #3E3E3E !important; }
  .blog-post a {
    text-decoration: none !important; }
  .blog-post .back-btn {
    font-size: 14px;
    font-weight: 500;
    margin: 30px 0 50px;
    margin-left: 130px;
    color: #3E3E3E !important;
    height: 20px; }
    .blog-post .back-btn span {
      margin-right: 10px; }
  .blog-post .blog-post-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 400px; }
    .blog-post .blog-post-container h1, .blog-post .blog-post-container h2, .blog-post .blog-post-container h3, .blog-post .blog-post-container h4, .blog-post .blog-post-container h5 {
      letter-spacing: 2px; }
    .blog-post .blog-post-container .blog-author {
      font-size: 14px;
      font-weight: 100;
      margin-top: 30px; }
    .blog-post .blog-post-container .blog-title {
      font-size: 50px;
      font-weight: 500;
      margin-top: 16px;
      line-height: 75px;
      letter-spacing: 2px; }
    .blog-post .blog-post-container .blog-content {
      position: relative;
      margin-top: 30px;
      font-weight: 200;
      font-size: 16px;
      width: 45vw;
      text-align: justify; }
    .blog-post .blog-post-container blockquote {
      color: #387C3F;
      font-size: 24px;
      border-left: solid 8px;
      margin: unset;
      border-radius: 10px;
      padding: 5px 20px;
      height: 30px;
      display: flex;
      flex-direction: column;
      width: fit-content;
      height: fit-content;
      margin-bottom: 15px; }
      .blog-post .blog-post-container blockquote p {
        margin: 0 !important; }
    .blog-post .blog-post-container cite {
      font-size: 16px;
      font-style: normal;
      margin-top: 5px; }
    .blog-post .blog-post-container figure {
      position: relative;
      margin: unset;
      text-align: center; }
      .blog-post .blog-post-container figure img {
        border-radius: 5px;
        width: 100%;
        height: auto;
        aspect-ratio: unset; }
    .blog-post .blog-post-container h2 {
      font-size: 26px; }
  .blog-post .navigation {
    display: flex;
    font-weight: 500;
    color: white;
    margin: 0 395px;
    width: 270px;
    justify-content: space-between;
    margin-bottom: 100px; }
    .blog-post .navigation .btn {
      background-color: #387C3F;
      width: 125px;
      height: 40px;
      display: flex;
      align-items: center;
      border-radius: 5px;
      justify-content: center;
      font-size: 12px;
      font-weight: 100; }
  .blog-post .more-posts {
    border-top: solid 1px #c7c7c7;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .blog-post .more-posts .more-posts-heading {
      margin-left: 130px;
      font-size: 20px;
      font-weight: 500; }
    .blog-post .more-posts .article-tile {
      width: 20%;
      height: 250px; }
      .blog-post .more-posts .article-tile .article-image {
        height: 150px; }
      .blog-post .more-posts .article-tile .article-title {
        font-size: 14px; }

@media (max-width: 768px) {
  .blog-post .back-btn {
    font-size: 14px;
    font-weight: 500;
    margin-top: 100px;
    margin-left: 18px; }
  .blog-post .blog-post-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 50px; }
    .blog-post .blog-post-container .blog-title {
      font-size: 30px;
      text-align: center; }
    .blog-post .blog-post-container .blog-content {
      width: 80vw; }
  .blog-post .navigation {
    margin: 0 50px;
    width: 270px;
    margin-bottom: 60px; }
  .blog-post .more-posts {
    border-top: solid 1px #c7c7c7;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center; }
    .blog-post .more-posts .more-posts-heading {
      margin-left: 0px;
      font-size: 24px;
      font-weight: 600; }
    .blog-post .more-posts .article-tile {
      width: 90%;
      height: 280px; }
      .blog-post .more-posts .article-tile .article-image {
        height: 130px; }
      .blog-post .more-posts .article-tile .article-title {
        font-size: 16px; } }

.user-deletion a {
  color: #387C3F; }

.user-deletion li {
  margin-bottom: 10px; }

.user-deletion .phone-delete {
  height: 500px; }

.screen {
  min-height: 400px; }

.green {
  color: #387C3F; }

.green-alpha {
  color: #387C3F1A; }

.green-bg {
  background-color: #387C3F; }

.white-bg {
  background-color: #FFF; }

.green-alpha-bg {
  background-color: #387C3F1A; }

.red {
  color: #F71202; }

.charcoal {
  color: #646464; }

.k-charcoal {
  color: #3E3E3E; }

.grey {
  color: #3E3E3E; }

.col {
  display: flex;
  flex-direction: column; }

.row {
  display: flex;
  flex-direction: row; }

.row-center {
  display: flex;
  flex-direction: row;
  align-items: center; }

.column-center {
  display: flex;
  flex-direction: column;
  align-items: center; }

.flex-center-content {
  display: flex;
  justify-content: center;
  align-items: center; }

.block {
  display: block; }

.roboto {
  font-family: 'Roboto', sans-serif; }

.center {
  margin: auto; }

.pointer {
  cursor: pointer; }

.justify-content-center {
  display: flex;
  justify-content: center; }

.justify-content-space-between {
  display: flex;
  justify-content: space-between; }

.center-text {
  text-align: center; }

.no-bullet {
  list-style-type: none; }

.capitalize {
  text-transform: capitalize; }

.uppercase {
  text-transform: uppercase; }

.title {
  font-size: 28px;
  font-weight: 600;
  width: 35%;
  text-align: center;
  color: #3E3E3E; }
  @media (max-width: 768px) {
    .title {
      width: 100%;
      font-size: 22px; } }

.sub-title {
  color: #8F8F8F;
  font-size: 14px;
  font-weight: 500;
  margin: 20px 0;
  text-align: center; }
  @media (max-width: 768px) {
    .sub-title {
      margin: 20px 10px;
      line-height: 25px; } }

.white-btn {
  background-color: white;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 10px;
  color: #387C3F;
  margin-right: 20px;
  width: fit-content; }
