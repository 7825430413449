@font-face {
  font-family: 'FontAwesome-Solid';
  src:  url('fonts/FontAwesome-Solid.eot?pmg818');
  src:  url('fonts/FontAwesome-Solid.eot?pmg818#iefix') format('embedded-opentype'),
    url('fonts/FontAwesome-Solid.ttf?pmg818') format('truetype'),
    url('fonts/FontAwesome-Solid.woff?pmg818') format('woff'),
    url('fonts/FontAwesome-Solid.svg?pmg818#FontAwesome-Solid') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="fas-"], [class*=" fas-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'FontAwesome-Solid' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fas-grin-frown-sweat:before {
  content: "\e900";
}
.fas-lips:before {
  content: "\e901";
}
.fas-abacus:before {
  content: "\e902";
}
.fas-acorn:before {
  content: "\e903";
}
.fas-ad:before {
  content: "\e904";
}
.fas-address-book:before {
  content: "\e905";
}
.fas-address-card:before {
  content: "\e906";
}
.fas-adjust:before {
  content: "\e907";
}
.fas-air-freshener:before {
  content: "\e908";
}
.fas-alarm-clock:before {
  content: "\e909";
}
.fas-alicorn:before {
  content: "\e90a";
}
.fas-align-center:before {
  content: "\e90b";
}
.fas-align-justify:before {
  content: "\e90c";
}
.fas-align-left:before {
  content: "\e90d";
}
.fas-align-right:before {
  content: "\e90e";
}
.fas-allergies:before {
  content: "\e90f";
}
.fas-ambulance:before {
  content: "\e910";
}
.fas-american-sign-language-interpreting:before {
  content: "\e911";
}
.fas-analytics:before {
  content: "\e912";
}
.fas-anchor:before {
  content: "\e913";
}
.fas-angel:before {
  content: "\e914";
}
.fas-angle-double-down:before {
  content: "\e915";
}
.fas-angle-double-left:before {
  content: "\e916";
}
.fas-angle-double-right:before {
  content: "\e917";
}
.fas-angle-double-up:before {
  content: "\e918";
}
.fas-angle-down:before {
  content: "\e919";
}
.fas-angle-left:before {
  content: "\e91a";
}
.fas-angle-right:before {
  content: "\e91b";
}
.fas-angle-up:before {
  content: "\e91c";
}
.fas-angry:before {
  content: "\e91d";
}
.fas-ankh:before {
  content: "\e91e";
}
.fas-apple-alt:before {
  content: "\e91f";
}
.fas-apple-crate:before {
  content: "\e920";
}
.fas-archive:before {
  content: "\e921";
}
.fas-archway:before {
  content: "\e922";
}
.fas-arrow-alt-circle-down:before {
  content: "\e923";
}
.fas-arrow-alt-circle-left:before {
  content: "\e924";
}
.fas-arrow-alt-circle-right:before {
  content: "\e925";
}
.fas-arrow-alt-circle-up:before {
  content: "\e926";
}
.fas-arrow-alt-down:before {
  content: "\e927";
}
.fas-arrow-alt-from-bottom:before {
  content: "\e928";
}
.fas-arrow-alt-from-left:before {
  content: "\e929";
}
.fas-arrow-alt-from-right:before {
  content: "\e92a";
}
.fas-arrow-alt-from-top:before {
  content: "\e92b";
}
.fas-arrow-alt-left:before {
  content: "\e92c";
}
.fas-arrow-alt-right:before {
  content: "\e92d";
}
.fas-arrow-alt-square-down:before {
  content: "\e92e";
}
.fas-arrow-alt-square-left:before {
  content: "\e92f";
}
.fas-arrow-alt-square-right:before {
  content: "\e930";
}
.fas-arrow-alt-square-up:before {
  content: "\e931";
}
.fas-arrow-alt-to-bottom:before {
  content: "\e932";
}
.fas-arrow-alt-to-left:before {
  content: "\e933";
}
.fas-arrow-alt-to-right:before {
  content: "\e934";
}
.fas-arrow-alt-to-top:before {
  content: "\e935";
}
.fas-arrow-alt-up:before {
  content: "\e936";
}
.fas-arrow-circle-down:before {
  content: "\e937";
}
.fas-arrow-circle-left:before {
  content: "\e938";
}
.fas-arrow-circle-right:before {
  content: "\e939";
}
.fas-arrow-circle-up:before {
  content: "\e93a";
}
.fas-arrow-down:before {
  content: "\e93b";
}
.fas-arrow-from-bottom:before {
  content: "\e93c";
}
.fas-arrow-from-left:before {
  content: "\e93d";
}
.fas-arrow-from-right:before {
  content: "\e93e";
}
.fas-arrow-from-top:before {
  content: "\e93f";
}
.fas-arrow-left:before {
  content: "\e940";
}
.fas-arrow-right:before {
  content: "\e941";
}
.fas-arrow-square-down:before {
  content: "\e942";
}
.fas-arrow-square-left:before {
  content: "\e943";
}
.fas-arrow-square-right:before {
  content: "\e944";
}
.fas-arrow-square-up:before {
  content: "\e945";
}
.fas-arrow-to-bottom:before {
  content: "\e946";
}
.fas-arrow-to-left:before {
  content: "\e947";
}
.fas-arrow-to-right:before {
  content: "\e948";
}
.fas-arrow-to-top:before {
  content: "\e949";
}
.fas-arrow-up:before {
  content: "\e94a";
}
.fas-arrows-alt-h:before {
  content: "\e94b";
}
.fas-arrows-alt-v:before {
  content: "\e94c";
}
.fas-arrows-alt:before {
  content: "\e94d";
}
.fas-arrows-h:before {
  content: "\e94e";
}
.fas-arrows-v:before {
  content: "\e94f";
}
.fas-arrows:before {
  content: "\e950";
}
.fas-assistive-listening-systems:before {
  content: "\e951";
}
.fas-asterisk:before {
  content: "\e952";
}
.fas-at:before {
  content: "\e953";
}
.fas-atlas:before {
  content: "\e954";
}
.fas-atom-alt:before {
  content: "\e955";
}
.fas-atom:before {
  content: "\e956";
}
.fas-audio-description:before {
  content: "\e957";
}
.fas-award:before {
  content: "\e958";
}
.fas-axe-battle:before {
  content: "\e959";
}
.fas-axe:before {
  content: "\e95a";
}
.fas-baby-carriage:before {
  content: "\e95b";
}
.fas-baby:before {
  content: "\e95c";
}
.fas-backpack:before {
  content: "\e95d";
}
.fas-backspace:before {
  content: "\e95e";
}
.fas-backward:before {
  content: "\e95f";
}
.fas-bacon:before {
  content: "\e960";
}
.fas-badge-check:before {
  content: "\e961";
}
.fas-badge-dollar:before {
  content: "\e962";
}
.fas-badge-percent:before {
  content: "\e963";
}
.fas-badge:before {
  content: "\e964";
}
.fas-badger-honey:before {
  content: "\e965";
}
.fas-balance-scale-left:before {
  content: "\e966";
}
.fas-balance-scale-right:before {
  content: "\e967";
}
.fas-balance-scale:before {
  content: "\e968";
}
.fas-ball-pile:before {
  content: "\e969";
}
.fas-ballot-check:before {
  content: "\e96a";
}
.fas-ballot:before {
  content: "\e96b";
}
.fas-ban:before {
  content: "\e96c";
}
.fas-band-aid:before {
  content: "\e96d";
}
.fas-barcode-alt:before {
  content: "\e96e";
}
.fas-barcode-read:before {
  content: "\e96f";
}
.fas-barcode-scan:before {
  content: "\e970";
}
.fas-barcode:before {
  content: "\e971";
}
.fas-bars:before {
  content: "\e972";
}
.fas-baseball-ball:before {
  content: "\e973";
}
.fas-baseball:before {
  content: "\e974";
}
.fas-basketball-ball:before {
  content: "\e975";
}
.fas-basketball-hoop:before {
  content: "\e976";
}
.fas-bat:before {
  content: "\e977";
}
.fas-bath:before {
  content: "\e978";
}
.fas-battery-bolt:before {
  content: "\e979";
}
.fas-battery-empty:before {
  content: "\e97a";
}
.fas-battery-full:before {
  content: "\e97b";
}
.fas-battery-half:before {
  content: "\e97c";
}
.fas-battery-quarter:before {
  content: "\e97d";
}
.fas-battery-slash:before {
  content: "\e97e";
}
.fas-battery-three-quarters:before {
  content: "\e97f";
}
.fas-bed:before {
  content: "\e980";
}
.fas-beer:before {
  content: "\e981";
}
.fas-bell-school-slash:before {
  content: "\e982";
}
.fas-bell-school:before {
  content: "\e983";
}
.fas-bell-slash:before {
  content: "\e984";
}
.fas-bell:before {
  content: "\e985";
}
.fas-bells:before {
  content: "\e986";
}
.fas-bezier-curve:before {
  content: "\e987";
}
.fas-bible:before {
  content: "\e988";
}
.fas-bicycle:before {
  content: "\e989";
}
.fas-binoculars:before {
  content: "\e98a";
}
.fas-biohazard:before {
  content: "\e98b";
}
.fas-birthday-cake:before {
  content: "\e98c";
}
.fas-blanket:before {
  content: "\e98d";
}
.fas-blender-phone:before {
  content: "\e98e";
}
.fas-blender:before {
  content: "\e98f";
}
.fas-blind:before {
  content: "\e990";
}
.fas-blog:before {
  content: "\e991";
}
.fas-bold:before {
  content: "\e992";
}
.fas-bolt:before {
  content: "\e993";
}
.fas-bomb:before {
  content: "\e994";
}
.fas-bone-break:before {
  content: "\e995";
}
.fas-bone:before {
  content: "\e996";
}
.fas-bong:before {
  content: "\e997";
}
.fas-book-alt:before {
  content: "\e998";
}
.fas-book-dead:before {
  content: "\e999";
}
.fas-book-heart:before {
  content: "\e99a";
}
.fas-book-medical:before {
  content: "\e99b";
}
.fas-book-open:before {
  content: "\e99c";
}
.fas-book-reader:before {
  content: "\e99d";
}
.fas-book-spells:before {
  content: "\e99e";
}
.fas-book-user:before {
  content: "\e99f";
}
.fas-book:before {
  content: "\e9a0";
}
.fas-bookmark:before {
  content: "\e9a1";
}
.fas-books-medical:before {
  content: "\e9a2";
}
.fas-books:before {
  content: "\e9a3";
}
.fas-boot:before {
  content: "\e9a4";
}
.fas-booth-curtain:before {
  content: "\e9a5";
}
.fas-bow-arrow:before {
  content: "\e9a6";
}
.fas-bowling-ball:before {
  content: "\e9a7";
}
.fas-bowling-pins:before {
  content: "\e9a8";
}
.fas-box-alt:before {
  content: "\e9a9";
}
.fas-box-ballot:before {
  content: "\e9aa";
}
.fas-box-check:before {
  content: "\e9ab";
}
.fas-box-fragile:before {
  content: "\e9ac";
}
.fas-box-full:before {
  content: "\e9ad";
}
.fas-box-heart:before {
  content: "\e9ae";
}
.fas-box-open:before {
  content: "\e9af";
}
.fas-box-up:before {
  content: "\e9b0";
}
.fas-box-usd:before {
  content: "\e9b1";
}
.fas-box:before {
  content: "\e9b2";
}
.fas-boxes-alt:before {
  content: "\e9b3";
}
.fas-boxes:before {
  content: "\e9b4";
}
.fas-boxing-glove:before {
  content: "\e9b5";
}
.fas-brackets-curly:before {
  content: "\e9b6";
}
.fas-brackets:before {
  content: "\e9b7";
}
.fas-braille:before {
  content: "\e9b8";
}
.fas-brain:before {
  content: "\e9b9";
}
.fas-bread-loaf:before {
  content: "\e9ba";
}
.fas-bread-slice:before {
  content: "\e9bb";
}
.fas-briefcase-medical:before {
  content: "\e9bc";
}
.fas-briefcase:before {
  content: "\e9bd";
}
.fas-broadcast-tower:before {
  content: "\e9be";
}
.fas-broom:before {
  content: "\e9bf";
}
.fas-browser:before {
  content: "\e9c0";
}
.fas-brush:before {
  content: "\e9c1";
}
.fas-bug:before {
  content: "\e9c2";
}
.fas-building:before {
  content: "\e9c3";
}
.fas-bullhorn:before {
  content: "\e9c4";
}
.fas-bullseye-arrow:before {
  content: "\e9c5";
}
.fas-bullseye-pointer:before {
  content: "\e9c6";
}
.fas-bullseye:before {
  content: "\e9c7";
}
.fas-burn:before {
  content: "\e9c8";
}
.fas-burrito:before {
  content: "\e9c9";
}
.fas-bus-alt:before {
  content: "\e9ca";
}
.fas-bus-school:before {
  content: "\e9cb";
}
.fas-bus:before {
  content: "\e9cc";
}
.fas-business-time:before {
  content: "\e9cd";
}
.fas-cabinet-filing:before {
  content: "\e9ce";
}
.fas-calculator-alt:before {
  content: "\e9cf";
}
.fas-calculator:before {
  content: "\e9d0";
}
.fas-calendar-alt:before {
  content: "\e9d1";
}
.fas-calendar-check:before {
  content: "\e9d2";
}
.fas-calendar-day:before {
  content: "\e9d3";
}
.fas-calendar-edit:before {
  content: "\e9d4";
}
.fas-calendar-exclamation:before {
  content: "\e9d5";
}
.fas-calendar-minus:before {
  content: "\e9d6";
}
.fas-calendar-plus:before {
  content: "\e9d7";
}
.fas-calendar-star:before {
  content: "\e9d8";
}
.fas-calendar-times:before {
  content: "\e9d9";
}
.fas-calendar-week:before {
  content: "\e9da";
}
.fas-calendar:before {
  content: "\e9db";
}
.fas-camera-alt:before {
  content: "\e9dc";
}
.fas-camera-retro:before {
  content: "\e9dd";
}
.fas-camera:before {
  content: "\e9de";
}
.fas-campfire:before {
  content: "\e9df";
}
.fas-campground:before {
  content: "\e9e0";
}
.fas-candle-holder:before {
  content: "\e9e1";
}
.fas-candy-cane:before {
  content: "\e9e2";
}
.fas-candy-corn:before {
  content: "\e9e3";
}
.fas-cannabis:before {
  content: "\e9e4";
}
.fas-capsules:before {
  content: "\e9e5";
}
.fas-car-alt:before {
  content: "\e9e6";
}
.fas-car-battery:before {
  content: "\e9e7";
}
.fas-car-bump:before {
  content: "\e9e8";
}
.fas-car-crash:before {
  content: "\e9e9";
}
.fas-car-garage:before {
  content: "\e9ea";
}
.fas-car-mechanic:before {
  content: "\e9eb";
}
.fas-car-side:before {
  content: "\e9ec";
}
.fas-car-tilt:before {
  content: "\e9ed";
}
.fas-car-wash:before {
  content: "\e9ee";
}
.fas-car:before {
  content: "\e9ef";
}
.fas-caret-circle-down:before {
  content: "\e9f0";
}
.fas-caret-circle-left:before {
  content: "\e9f1";
}
.fas-caret-circle-right:before {
  content: "\e9f2";
}
.fas-caret-circle-up:before {
  content: "\e9f3";
}
.fas-caret-down:before {
  content: "\e9f4";
}
.fas-caret-left:before {
  content: "\e9f5";
}
.fas-caret-right:before {
  content: "\e9f6";
}
.fas-caret-square-down:before {
  content: "\e9f7";
}
.fas-caret-square-left:before {
  content: "\e9f8";
}
.fas-caret-square-right:before {
  content: "\e9f9";
}
.fas-caret-square-up:before {
  content: "\e9fa";
}
.fas-caret-up:before {
  content: "\e9fb";
}
.fas-carrot:before {
  content: "\e9fc";
}
.fas-cart-arrow-down:before {
  content: "\e9fd";
}
.fas-cart-plus:before {
  content: "\e9fe";
}
.fas-cash-register:before {
  content: "\e9ff";
}
.fas-cat:before {
  content: "\ea00";
}
.fas-cauldron:before {
  content: "\ea01";
}
.fas-certificate:before {
  content: "\ea02";
}
.fas-chair-office:before {
  content: "\ea03";
}
.fas-chair:before {
  content: "\ea04";
}
.fas-chalkboard-teacher:before {
  content: "\ea05";
}
.fas-chalkboard:before {
  content: "\ea06";
}
.fas-charging-station:before {
  content: "\ea07";
}
.fas-chart-area:before {
  content: "\ea08";
}
.fas-chart-bar:before {
  content: "\ea09";
}
.fas-chart-line-down:before {
  content: "\ea0a";
}
.fas-chart-line:before {
  content: "\ea0b";
}
.fas-chart-network:before {
  content: "\ea0c";
}
.fas-chart-pie-alt:before {
  content: "\ea0d";
}
.fas-chart-pie:before {
  content: "\ea0e";
}
.fas-chart-scatter:before {
  content: "\ea0f";
}
.fas-check-circle:before {
  content: "\ea10";
}
.fas-check-double:before {
  content: "\ea11";
}
.fas-check-square:before {
  content: "\ea12";
}
.fas-check:before {
  content: "\ea13";
}
.fas-cheese-swiss:before {
  content: "\ea14";
}
.fas-cheese:before {
  content: "\ea15";
}
.fas-cheeseburger:before {
  content: "\ea16";
}
.fas-chess-bishop-alt:before {
  content: "\ea17";
}
.fas-chess-bishop:before {
  content: "\ea18";
}
.fas-chess-board:before {
  content: "\ea19";
}
.fas-chess-clock-alt:before {
  content: "\ea1a";
}
.fas-chess-clock:before {
  content: "\ea1b";
}
.fas-chess-king-alt:before {
  content: "\ea1c";
}
.fas-chess-king:before {
  content: "\ea1d";
}
.fas-chess-knight-alt:before {
  content: "\ea1e";
}
.fas-chess-knight:before {
  content: "\ea1f";
}
.fas-chess-pawn-alt:before {
  content: "\ea20";
}
.fas-chess-pawn:before {
  content: "\ea21";
}
.fas-chess-queen-alt:before {
  content: "\ea22";
}
.fas-chess-queen:before {
  content: "\ea23";
}
.fas-chess-rook-alt:before {
  content: "\ea24";
}
.fas-chess-rook:before {
  content: "\ea25";
}
.fas-chess:before {
  content: "\ea26";
}
.fas-chevron-circle-down:before {
  content: "\ea27";
}
.fas-chevron-circle-left:before {
  content: "\ea28";
}
.fas-chevron-circle-right:before {
  content: "\ea29";
}
.fas-chevron-circle-up:before {
  content: "\ea2a";
}
.fas-chevron-double-down:before {
  content: "\ea2b";
}
.fas-chevron-double-left:before {
  content: "\ea2c";
}
.fas-chevron-double-right:before {
  content: "\ea2d";
}
.fas-chevron-double-up:before {
  content: "\ea2e";
}
.fas-chevron-down:before {
  content: "\ea2f";
}
.fas-chevron-left:before {
  content: "\ea30";
}
.fas-chevron-right:before {
  content: "\ea31";
}
.fas-chevron-square-down:before {
  content: "\ea32";
}
.fas-chevron-square-left:before {
  content: "\ea33";
}
.fas-chevron-square-right:before {
  content: "\ea34";
}
.fas-chevron-square-up:before {
  content: "\ea35";
}
.fas-chevron-up:before {
  content: "\ea36";
}
.fas-child:before {
  content: "\ea37";
}
.fas-chimney:before {
  content: "\ea38";
}
.fas-church:before {
  content: "\ea39";
}
.fas-circle-notch:before {
  content: "\ea3a";
}
.fas-circle:before {
  content: "\ea3b";
}
.fas-city:before {
  content: "\ea3c";
}
.fas-claw-marks:before {
  content: "\ea3d";
}
.fas-clinic-medical:before {
  content: "\ea3e";
}
.fas-clipboard-check:before {
  content: "\ea3f";
}
.fas-clipboard-list-check:before {
  content: "\ea40";
}
.fas-clipboard-list:before {
  content: "\ea41";
}
.fas-clipboard-prescription:before {
  content: "\ea42";
}
.fas-clipboard-user:before {
  content: "\ea43";
}
.fas-clipboard:before {
  content: "\ea44";
}
.fas-clock:before {
  content: "\ea45";
}
.fas-clone:before {
  content: "\ea46";
}
.fas-closed-captioning:before {
  content: "\ea47";
}
.fas-cloud-download-alt:before {
  content: "\ea48";
}
.fas-cloud-download:before {
  content: "\ea49";
}
.fas-cloud-drizzle:before {
  content: "\ea4a";
}
.fas-cloud-hail-mixed:before {
  content: "\ea4b";
}
.fas-cloud-hail:before {
  content: "\ea4c";
}
.fas-cloud-meatball:before {
  content: "\ea4d";
}
.fas-cloud-moon-rain:before {
  content: "\ea4e";
}
.fas-cloud-moon:before {
  content: "\ea4f";
}
.fas-cloud-rain:before {
  content: "\ea50";
}
.fas-cloud-rainbow:before {
  content: "\ea51";
}
.fas-cloud-showers-heavy:before {
  content: "\ea52";
}
.fas-cloud-showers:before {
  content: "\ea53";
}
.fas-cloud-sleet:before {
  content: "\ea54";
}
.fas-cloud-snow:before {
  content: "\ea55";
}
.fas-cloud-sun-rain:before {
  content: "\ea56";
}
.fas-cloud-sun:before {
  content: "\ea57";
}
.fas-cloud-upload-alt:before {
  content: "\ea58";
}
.fas-cloud-upload:before {
  content: "\ea59";
}
.fas-cloud:before {
  content: "\ea5a";
}
.fas-clouds-moon:before {
  content: "\ea5b";
}
.fas-clouds-sun:before {
  content: "\ea5c";
}
.fas-clouds:before {
  content: "\ea5d";
}
.fas-club:before {
  content: "\ea5e";
}
.fas-cocktail:before {
  content: "\ea5f";
}
.fas-code-branch:before {
  content: "\ea60";
}
.fas-code-commit:before {
  content: "\ea61";
}
.fas-code-merge:before {
  content: "\ea62";
}
.fas-code:before {
  content: "\ea63";
}
.fas-coffee-togo:before {
  content: "\ea64";
}
.fas-coffee:before {
  content: "\ea65";
}
.fas-coffin:before {
  content: "\ea66";
}
.fas-cog:before {
  content: "\ea67";
}
.fas-cogs:before {
  content: "\ea68";
}
.fas-coins:before {
  content: "\ea69";
}
.fas-columns:before {
  content: "\ea6a";
}
.fas-comment-alt-check:before {
  content: "\ea6b";
}
.fas-comment-alt-dollar:before {
  content: "\ea6c";
}
.fas-comment-alt-dots:before {
  content: "\ea6d";
}
.fas-comment-alt-edit:before {
  content: "\ea6e";
}
.fas-comment-alt-exclamation:before {
  content: "\ea6f";
}
.fas-comment-alt-lines:before {
  content: "\ea70";
}
.fas-comment-alt-medical:before {
  content: "\ea71";
}
.fas-comment-alt-minus:before {
  content: "\ea72";
}
.fas-comment-alt-plus:before {
  content: "\ea73";
}
.fas-comment-alt-slash:before {
  content: "\ea74";
}
.fas-comment-alt-smile:before {
  content: "\ea75";
}
.fas-comment-alt-times:before {
  content: "\ea76";
}
.fas-comment-alt:before {
  content: "\ea77";
}
.fas-comment-check:before {
  content: "\ea78";
}
.fas-comment-dollar:before {
  content: "\ea79";
}
.fas-comment-dots:before {
  content: "\ea7a";
}
.fas-comment-edit:before {
  content: "\ea7b";
}
.fas-comment-exclamation:before {
  content: "\ea7c";
}
.fas-comment-lines:before {
  content: "\ea7d";
}
.fas-comment-medical:before {
  content: "\ea7e";
}
.fas-comment-minus:before {
  content: "\ea7f";
}
.fas-comment-plus:before {
  content: "\ea80";
}
.fas-comment-slash:before {
  content: "\ea81";
}
.fas-comment-smile:before {
  content: "\ea82";
}
.fas-comment-times:before {
  content: "\ea83";
}
.fas-comment:before {
  content: "\ea84";
}
.fas-comments-alt-dollar:before {
  content: "\ea85";
}
.fas-comments-alt:before {
  content: "\ea86";
}
.fas-comments-dollar:before {
  content: "\ea87";
}
.fas-comments:before {
  content: "\ea88";
}
.fas-compact-disc:before {
  content: "\ea89";
}
.fas-compass-slash:before {
  content: "\ea8a";
}
.fas-compass:before {
  content: "\ea8b";
}
.fas-compress-alt:before {
  content: "\ea8c";
}
.fas-compress-arrows-alt:before {
  content: "\ea8d";
}
.fas-compress-wide:before {
  content: "\ea8e";
}
.fas-compress:before {
  content: "\ea8f";
}
.fas-concierge-bell:before {
  content: "\ea90";
}
.fas-container-storage:before {
  content: "\ea91";
}
.fas-conveyor-belt-alt:before {
  content: "\ea92";
}
.fas-conveyor-belt:before {
  content: "\ea93";
}
.fas-cookie-bite:before {
  content: "\ea94";
}
.fas-cookie:before {
  content: "\ea95";
}
.fas-copy:before {
  content: "\ea96";
}
.fas-copyright:before {
  content: "\ea97";
}
.fas-corn:before {
  content: "\ea98";
}
.fas-couch:before {
  content: "\ea99";
}
.fas-cow:before {
  content: "\ea9a";
}
.fas-credit-card-blank:before {
  content: "\ea9b";
}
.fas-credit-card-front:before {
  content: "\ea9c";
}
.fas-credit-card:before {
  content: "\ea9d";
}
.fas-cricket:before {
  content: "\ea9e";
}
.fas-croissant:before {
  content: "\ea9f";
}
.fas-crop-alt:before {
  content: "\eaa0";
}
.fas-crop:before {
  content: "\eaa1";
}
.fas-cross:before {
  content: "\eaa2";
}
.fas-crosshairs:before {
  content: "\eaa3";
}
.fas-crow:before {
  content: "\eaa4";
}
.fas-crown:before {
  content: "\eaa5";
}
.fas-crutch:before {
  content: "\eaa6";
}
.fas-crutches:before {
  content: "\eaa7";
}
.fas-cube:before {
  content: "\eaa8";
}
.fas-cubes:before {
  content: "\eaa9";
}
.fas-curling:before {
  content: "\eaaa";
}
.fas-cut:before {
  content: "\eaab";
}
.fas-dagger:before {
  content: "\eaac";
}
.fas-database:before {
  content: "\eaad";
}
.fas-deaf:before {
  content: "\eaae";
}
.fas-debug:before {
  content: "\eaaf";
}
.fas-deer-rudolph:before {
  content: "\eab0";
}
.fas-deer:before {
  content: "\eab1";
}
.fas-democrat:before {
  content: "\eab2";
}
.fas-desktop-alt:before {
  content: "\eab3";
}
.fas-desktop:before {
  content: "\eab4";
}
.fas-dewpoint:before {
  content: "\eab5";
}
.fas-dharmachakra:before {
  content: "\eab6";
}
.fas-diagnoses:before {
  content: "\eab7";
}
.fas-diamond:before {
  content: "\eab8";
}
.fas-dice-d4:before {
  content: "\eab9";
}
.fas-dice-d6:before {
  content: "\eaba";
}
.fas-dice-d8:before {
  content: "\eabb";
}
.fas-dice-d10:before {
  content: "\eabc";
}
.fas-dice-d12:before {
  content: "\eabd";
}
.fas-dice-d20:before {
  content: "\eabe";
}
.fas-dice-five:before {
  content: "\eabf";
}
.fas-dice-four:before {
  content: "\eac0";
}
.fas-dice-one:before {
  content: "\eac1";
}
.fas-dice-six:before {
  content: "\eac2";
}
.fas-dice-three:before {
  content: "\eac3";
}
.fas-dice-two:before {
  content: "\eac4";
}
.fas-dice:before {
  content: "\eac5";
}
.fas-digital-tachograph:before {
  content: "\eac6";
}
.fas-diploma:before {
  content: "\eac7";
}
.fas-directions:before {
  content: "\eac8";
}
.fas-disease:before {
  content: "\eac9";
}
.fas-divide:before {
  content: "\eaca";
}
.fas-dizzy:before {
  content: "\eacb";
}
.fas-dna:before {
  content: "\eacc";
}
.fas-do-not-enter:before {
  content: "\eacd";
}
.fas-dog-leashed:before {
  content: "\eace";
}
.fas-dog:before {
  content: "\eacf";
}
.fas-dollar-sign:before {
  content: "\ead0";
}
.fas-dolly-empty:before {
  content: "\ead1";
}
.fas-dolly-flatbed-alt:before {
  content: "\ead2";
}
.fas-dolly-flatbed-empty:before {
  content: "\ead3";
}
.fas-dolly-flatbed:before {
  content: "\ead4";
}
.fas-dolly:before {
  content: "\ead5";
}
.fas-donate:before {
  content: "\ead6";
}
.fas-door-closed:before {
  content: "\ead7";
}
.fas-door-open:before {
  content: "\ead8";
}
.fas-dot-circle:before {
  content: "\ead9";
}
.fas-dove:before {
  content: "\eada";
}
.fas-download:before {
  content: "\eadb";
}
.fas-drafting-compass:before {
  content: "\eadc";
}
.fas-dragon:before {
  content: "\eadd";
}
.fas-draw-circle:before {
  content: "\eade";
}
.fas-draw-polygon:before {
  content: "\eadf";
}
.fas-draw-square:before {
  content: "\eae0";
}
.fas-dreidel:before {
  content: "\eae1";
}
.fas-drum-steelpan:before {
  content: "\eae2";
}
.fas-drum:before {
  content: "\eae3";
}
.fas-drumstick-bite:before {
  content: "\eae4";
}
.fas-drumstick:before {
  content: "\eae5";
}
.fas-duck:before {
  content: "\eae6";
}
.fas-dumbbell:before {
  content: "\eae7";
}
.fas-dumpster-fire:before {
  content: "\eae8";
}
.fas-dumpster:before {
  content: "\eae9";
}
.fas-dungeon:before {
  content: "\eaea";
}
.fas-ear-muffs:before {
  content: "\eaeb";
}
.fas-ear:before {
  content: "\eaec";
}
.fas-eclipse-alt:before {
  content: "\eaed";
}
.fas-eclipse:before {
  content: "\eaee";
}
.fas-edit:before {
  content: "\eaef";
}
.fas-egg-fried:before {
  content: "\eaf0";
}
.fas-egg:before {
  content: "\eaf1";
}
.fas-eject:before {
  content: "\eaf2";
}
.fas-elephant:before {
  content: "\eaf3";
}
.fas-ellipsis-h-alt:before {
  content: "\eaf4";
}
.fas-ellipsis-h:before {
  content: "\eaf5";
}
.fas-ellipsis-v-alt:before {
  content: "\eaf6";
}
.fas-ellipsis-v:before {
  content: "\eaf7";
}
.fas-empty-set:before {
  content: "\eaf8";
}
.fas-engine-warning:before {
  content: "\eaf9";
}
.fas-envelope-open-dollar:before {
  content: "\eafa";
}
.fas-envelope-open-text:before {
  content: "\eafb";
}
.fas-envelope-open:before {
  content: "\eafc";
}
.fas-envelope-square:before {
  content: "\eafd";
}
.fas-envelope:before {
  content: "\eafe";
}
.fas-equals:before {
  content: "\eaff";
}
.fas-eraser:before {
  content: "\eb00";
}
.fas-ethernet:before {
  content: "\eb01";
}
.fas-euro-sign:before {
  content: "\eb02";
}
.fas-exchange-alt:before {
  content: "\eb03";
}
.fas-exchange:before {
  content: "\eb04";
}
.fas-exclamation-circle:before {
  content: "\eb05";
}
.fas-exclamation-square:before {
  content: "\eb06";
}
.fas-exclamation-triangle:before {
  content: "\eb07";
}
.fas-exclamation:before {
  content: "\eb08";
}
.fas-expand-alt:before {
  content: "\eb09";
}
.fas-expand-arrows-alt:before {
  content: "\eb0a";
}
.fas-expand-arrows:before {
  content: "\eb0b";
}
.fas-expand-wide:before {
  content: "\eb0c";
}
.fas-expand:before {
  content: "\eb0d";
}
.fas-external-link-alt:before {
  content: "\eb0e";
}
.fas-external-link-square-alt:before {
  content: "\eb0f";
}
.fas-external-link-square:before {
  content: "\eb10";
}
.fas-external-link:before {
  content: "\eb11";
}
.fas-eye-dropper:before {
  content: "\eb12";
}
.fas-eye-evil:before {
  content: "\eb13";
}
.fas-eye-slash:before {
  content: "\eb14";
}
.fas-eye:before {
  content: "\eb15";
}
.fas-fast-backward:before {
  content: "\eb16";
}
.fas-fast-forward:before {
  content: "\eb17";
}
.fas-fax:before {
  content: "\eb18";
}
.fas-feather-alt:before {
  content: "\eb19";
}
.fas-feather:before {
  content: "\eb1a";
}
.fas-female:before {
  content: "\eb1b";
}
.fas-field-hockey:before {
  content: "\eb1c";
}
.fas-fighter-jet:before {
  content: "\eb1d";
}
.fas-file-alt:before {
  content: "\eb1e";
}
.fas-file-archive:before {
  content: "\eb1f";
}
.fas-file-audio:before {
  content: "\eb20";
}
.fas-file-certificate:before {
  content: "\eb21";
}
.fas-file-chart-line:before {
  content: "\eb22";
}
.fas-file-chart-pie:before {
  content: "\eb23";
}
.fas-file-check:before {
  content: "\eb24";
}
.fas-file-code:before {
  content: "\eb25";
}
.fas-file-contract:before {
  content: "\eb26";
}
.fas-file-csv:before {
  content: "\eb27";
}
.fas-file-download:before {
  content: "\eb28";
}
.fas-file-edit:before {
  content: "\eb29";
}
.fas-file-excel:before {
  content: "\eb2a";
}
.fas-file-exclamation:before {
  content: "\eb2b";
}
.fas-file-export:before {
  content: "\eb2c";
}
.fas-file-image:before {
  content: "\eb2d";
}
.fas-file-import:before {
  content: "\eb2e";
}
.fas-file-invoice-dollar:before {
  content: "\eb2f";
}
.fas-file-invoice:before {
  content: "\eb30";
}
.fas-file-medical-alt:before {
  content: "\eb31";
}
.fas-file-medical:before {
  content: "\eb32";
}
.fas-file-minus:before {
  content: "\eb33";
}
.fas-file-pdf:before {
  content: "\eb34";
}
.fas-file-plus:before {
  content: "\eb35";
}
.fas-file-powerpoint:before {
  content: "\eb36";
}
.fas-file-prescription:before {
  content: "\eb37";
}
.fas-file-signature:before {
  content: "\eb38";
}
.fas-file-spreadsheet:before {
  content: "\eb39";
}
.fas-file-times:before {
  content: "\eb3a";
}
.fas-file-upload:before {
  content: "\eb3b";
}
.fas-file-user:before {
  content: "\eb3c";
}
.fas-file-video:before {
  content: "\eb3d";
}
.fas-file-word:before {
  content: "\eb3e";
}
.fas-file:before {
  content: "\eb3f";
}
.fas-files-medical:before {
  content: "\eb40";
}
.fas-fill-drip:before {
  content: "\eb41";
}
.fas-fill:before {
  content: "\eb42";
}
.fas-film-alt:before {
  content: "\eb43";
}
.fas-film:before {
  content: "\eb44";
}
.fas-filter:before {
  content: "\eb45";
}
.fas-fingerprint:before {
  content: "\eb46";
}
.fas-fire-alt:before {
  content: "\eb47";
}
.fas-fire-extinguisher:before {
  content: "\eb48";
}
.fas-fire-smoke:before {
  content: "\eb49";
}
.fas-fire:before {
  content: "\eb4a";
}
.fas-fireplace:before {
  content: "\eb4b";
}
.fas-first-aid:before {
  content: "\eb4c";
}
.fas-fish-cooked:before {
  content: "\eb4d";
}
.fas-fish:before {
  content: "\eb4e";
}
.fas-fist-raised:before {
  content: "\eb4f";
}
.fas-flag-alt:before {
  content: "\eb50";
}
.fas-flag-checkered:before {
  content: "\eb51";
}
.fas-flag-usa:before {
  content: "\eb52";
}
.fas-flag:before {
  content: "\eb53";
}
.fas-flame:before {
  content: "\eb54";
}
.fas-flask-poison:before {
  content: "\eb55";
}
.fas-flask-potion:before {
  content: "\eb56";
}
.fas-flask:before {
  content: "\eb57";
}
.fas-flower-daffodil:before {
  content: "\eb58";
}
.fas-flower-tulip:before {
  content: "\eb59";
}
.fas-flower:before {
  content: "\eb5a";
}
.fas-flushed:before {
  content: "\eb5b";
}
.fas-fog:before {
  content: "\eb5c";
}
.fas-folder-minus:before {
  content: "\eb5d";
}
.fas-folder-open:before {
  content: "\eb5e";
}
.fas-folder-plus:before {
  content: "\eb5f";
}
.fas-folder-times:before {
  content: "\eb60";
}
.fas-folder-tree:before {
  content: "\eb61";
}
.fas-folder:before {
  content: "\eb62";
}
.fas-folders:before {
  content: "\eb63";
}
.fas-font-awesome-logo-full:before {
  content: "\eb64";
}
.fas-font:before {
  content: "\eb65";
}
.fas-football-ball:before {
  content: "\eb66";
}
.fas-football-helmet:before {
  content: "\eb67";
}
.fas-forklift:before {
  content: "\eb68";
}
.fas-forward:before {
  content: "\eb69";
}
.fas-fragile:before {
  content: "\eb6a";
}
.fas-french-fries:before {
  content: "\eb6b";
}
.fas-frog:before {
  content: "\eb6c";
}
.fas-frosty-head:before {
  content: "\eb6d";
}
.fas-frown-open:before {
  content: "\eb6e";
}
.fas-frown:before {
  content: "\eb6f";
}
.fas-function:before {
  content: "\eb70";
}
.fas-funnel-dollar:before {
  content: "\eb71";
}
.fas-futbol:before {
  content: "\eb72";
}
.fas-gamepad:before {
  content: "\eb73";
}
.fas-gas-pump-slash:before {
  content: "\eb74";
}
.fas-gas-pump:before {
  content: "\eb75";
}
.fas-gavel:before {
  content: "\eb76";
}
.fas-gem:before {
  content: "\eb77";
}
.fas-genderless:before {
  content: "\eb78";
}
.fas-ghost:before {
  content: "\eb79";
}
.fas-gift-card:before {
  content: "\eb7a";
}
.fas-gift:before {
  content: "\eb7b";
}
.fas-gifts:before {
  content: "\eb7c";
}
.fas-gingerbread-man:before {
  content: "\eb7d";
}
.fas-glass-champagne:before {
  content: "\eb7e";
}
.fas-glass-cheers:before {
  content: "\eb7f";
}
.fas-glass-martini-alt:before {
  content: "\eb80";
}
.fas-glass-martini:before {
  content: "\eb81";
}
.fas-glass-whiskey-rocks:before {
  content: "\eb82";
}
.fas-glass-whiskey:before {
  content: "\eb83";
}
.fas-glass:before {
  content: "\eb84";
}
.fas-glasses-alt:before {
  content: "\eb85";
}
.fas-glasses:before {
  content: "\eb86";
}
.fas-globe-africa:before {
  content: "\eb87";
}
.fas-globe-americas:before {
  content: "\eb88";
}
.fas-globe-asia:before {
  content: "\eb89";
}
.fas-globe-europe:before {
  content: "\eb8a";
}
.fas-globe-snow:before {
  content: "\eb8b";
}
.fas-globe-stand:before {
  content: "\eb8c";
}
.fas-globe:before {
  content: "\eb8d";
}
.fas-golf-ball:before {
  content: "\eb8e";
}
.fas-golf-club:before {
  content: "\eb8f";
}
.fas-gopuram:before {
  content: "\eb90";
}
.fas-graduation-cap:before {
  content: "\eb91";
}
.fas-greater-than-equal:before {
  content: "\eb92";
}
.fas-greater-than:before {
  content: "\eb93";
}
.fas-grimace:before {
  content: "\eb94";
}
.fas-grin-alt:before {
  content: "\eb95";
}
.fas-grin-beam-sweat:before {
  content: "\eb96";
}
.fas-grin-beam:before {
  content: "\eb97";
}
.fas-grin-hearts:before {
  content: "\eb98";
}
.fas-grin-squint-tears:before {
  content: "\eb99";
}
.fas-grin-squint:before {
  content: "\eb9a";
}
.fas-grin-stars:before {
  content: "\eb9b";
}
.fas-grin-tears:before {
  content: "\eb9c";
}
.fas-grin-tongue-squint:before {
  content: "\eb9d";
}
.fas-grin-tongue-wink:before {
  content: "\eb9e";
}
.fas-grin-tongue:before {
  content: "\eb9f";
}
.fas-grin-wink:before {
  content: "\eba0";
}
.fas-grin:before {
  content: "\eba1";
}
.fas-grip-horizontal:before {
  content: "\eba2";
}
.fas-grip-lines-vertical:before {
  content: "\eba3";
}
.fas-grip-lines:before {
  content: "\eba4";
}
.fas-grip-vertical:before {
  content: "\eba5";
}
.fas-guitar:before {
  content: "\eba6";
}
.fas-h-square:before {
  content: "\eba7";
}
.fas-h1:before {
  content: "\eba8";
}
.fas-h2:before {
  content: "\eba9";
}
.fas-h3:before {
  content: "\ebaa";
}
.fas-hamburger:before {
  content: "\ebab";
}
.fas-hammer-war:before {
  content: "\ebac";
}
.fas-hammer:before {
  content: "\ebad";
}
.fas-hamsa:before {
  content: "\ebae";
}
.fas-hand-heart:before {
  content: "\ebaf";
}
.fas-hand-holding-box:before {
  content: "\ebb0";
}
.fas-hand-holding-heart:before {
  content: "\ebb1";
}
.fas-hand-holding-magic:before {
  content: "\ebb2";
}
.fas-hand-holding-seedling:before {
  content: "\ebb3";
}
.fas-hand-holding-usd:before {
  content: "\ebb4";
}
.fas-hand-holding-water:before {
  content: "\ebb5";
}
.fas-hand-holding:before {
  content: "\ebb6";
}
.fas-hand-lizard:before {
  content: "\ebb7";
}
.fas-hand-middle-finger:before {
  content: "\ebb8";
}
.fas-hand-paper:before {
  content: "\ebb9";
}
.fas-hand-peace:before {
  content: "\ebba";
}
.fas-hand-point-down:before {
  content: "\ebbb";
}
.fas-hand-point-left:before {
  content: "\ebbc";
}
.fas-hand-point-right:before {
  content: "\ebbd";
}
.fas-hand-point-up:before {
  content: "\ebbe";
}
.fas-hand-pointer:before {
  content: "\ebbf";
}
.fas-hand-receiving:before {
  content: "\ebc0";
}
.fas-hand-rock:before {
  content: "\ebc1";
}
.fas-hand-scissors:before {
  content: "\ebc2";
}
.fas-hand-spock:before {
  content: "\ebc3";
}
.fas-hands-heart:before {
  content: "\ebc4";
}
.fas-hands-helping:before {
  content: "\ebc5";
}
.fas-hands-usd:before {
  content: "\ebc6";
}
.fas-hands:before {
  content: "\ebc7";
}
.fas-handshake-alt:before {
  content: "\ebc8";
}
.fas-handshake:before {
  content: "\ebc9";
}
.fas-hanukiah:before {
  content: "\ebca";
}
.fas-hard-hat:before {
  content: "\ebcb";
}
.fas-hashtag:before {
  content: "\ebcc";
}
.fas-hat-santa:before {
  content: "\ebcd";
}
.fas-hat-winter:before {
  content: "\ebce";
}
.fas-hat-witch:before {
  content: "\ebcf";
}
.fas-hat-wizard:before {
  content: "\ebd0";
}
.fas-haykal:before {
  content: "\ebd1";
}
.fas-hdd:before {
  content: "\ebd2";
}
.fas-head-side-brain:before {
  content: "\ebd3";
}
.fas-head-side-medical:before {
  content: "\ebd4";
}
.fas-head-side:before {
  content: "\ebd5";
}
.fas-head-vr:before {
  content: "\ebd6";
}
.fas-heading:before {
  content: "\ebd7";
}
.fas-headphones-alt:before {
  content: "\ebd8";
}
.fas-headphones:before {
  content: "\ebd9";
}
.fas-headset:before {
  content: "\ebda";
}
.fas-heart-broken:before {
  content: "\ebdb";
}
.fas-heart-circle:before {
  content: "\ebdc";
}
.fas-heart-rate:before {
  content: "\ebdd";
}
.fas-heart-square:before {
  content: "\ebde";
}
.fas-heart:before {
  content: "\ebdf";
}
.fas-heartbeat:before {
  content: "\ebe0";
}
.fas-helicopter:before {
  content: "\ebe1";
}
.fas-helmet-battle:before {
  content: "\ebe2";
}
.fas-hexagon:before {
  content: "\ebe3";
}
.fas-highlighter:before {
  content: "\ebe4";
}
.fas-hiking:before {
  content: "\ebe5";
}
.fas-hippo:before {
  content: "\ebe6";
}
.fas-history:before {
  content: "\ebe7";
}
.fas-hockey-mask:before {
  content: "\ebe8";
}
.fas-hockey-puck:before {
  content: "\ebe9";
}
.fas-hockey-sticks:before {
  content: "\ebea";
}
.fas-holly-berry:before {
  content: "\ebeb";
}
.fas-home-alt:before {
  content: "\ebec";
}
.fas-home-heart:before {
  content: "\ebed";
}
.fas-home-lg-alt:before {
  content: "\ebee";
}
.fas-home-lg:before {
  content: "\ebef";
}
.fas-home:before {
  content: "\ebf0";
}
.fas-hood-cloak:before {
  content: "\ebf1";
}
.fas-horse-head:before {
  content: "\ebf2";
}
.fas-horse:before {
  content: "\ebf3";
}
.fas-hospital-alt:before {
  content: "\ebf4";
}
.fas-hospital-symbol:before {
  content: "\ebf5";
}
.fas-hospital-user:before {
  content: "\ebf6";
}
.fas-hospital:before {
  content: "\ebf7";
}
.fas-hospitals:before {
  content: "\ebf8";
}
.fas-hot-tub:before {
  content: "\ebf9";
}
.fas-hotdog:before {
  content: "\ebfa";
}
.fas-hotel:before {
  content: "\ebfb";
}
.fas-hourglass-end:before {
  content: "\ebfc";
}
.fas-hourglass-half:before {
  content: "\ebfd";
}
.fas-hourglass-start:before {
  content: "\ebfe";
}
.fas-hourglass:before {
  content: "\ebff";
}
.fas-house-damage:before {
  content: "\ec00";
}
.fas-house-flood:before {
  content: "\ec01";
}
.fas-hryvnia:before {
  content: "\ec02";
}
.fas-humidity:before {
  content: "\ec03";
}
.fas-hurricane:before {
  content: "\ec04";
}
.fas-i-cursor:before {
  content: "\ec05";
}
.fas-ice-cream:before {
  content: "\ec06";
}
.fas-ice-skate:before {
  content: "\ec07";
}
.fas-icicles:before {
  content: "\ec08";
}
.fas-id-badge:before {
  content: "\ec09";
}
.fas-id-card-alt:before {
  content: "\ec0a";
}
.fas-id-card:before {
  content: "\ec0b";
}
.fas-igloo:before {
  content: "\ec0c";
}
.fas-image:before {
  content: "\ec0d";
}
.fas-images:before {
  content: "\ec0e";
}
.fas-inbox-in:before {
  content: "\ec0f";
}
.fas-inbox-out:before {
  content: "\ec10";
}
.fas-inbox:before {
  content: "\ec11";
}
.fas-indent:before {
  content: "\ec12";
}
.fas-industry-alt:before {
  content: "\ec13";
}
.fas-industry:before {
  content: "\ec14";
}
.fas-infinity:before {
  content: "\ec15";
}
.fas-info-circle:before {
  content: "\ec16";
}
.fas-info-square:before {
  content: "\ec17";
}
.fas-info:before {
  content: "\ec18";
}
.fas-inhaler:before {
  content: "\ec19";
}
.fas-integral:before {
  content: "\ec1a";
}
.fas-intersection:before {
  content: "\ec1b";
}
.fas-inventory:before {
  content: "\ec1c";
}
.fas-island-tropical:before {
  content: "\ec1d";
}
.fas-italic:before {
  content: "\ec1e";
}
.fas-jack-o-lantern:before {
  content: "\ec1f";
}
.fas-jedi:before {
  content: "\ec20";
}
.fas-joint:before {
  content: "\ec21";
}
.fas-journal-whills:before {
  content: "\ec22";
}
.fas-kaaba:before {
  content: "\ec23";
}
.fas-key-skeleton:before {
  content: "\ec24";
}
.fas-key:before {
  content: "\ec25";
}
.fas-keyboard:before {
  content: "\ec26";
}
.fas-keynote:before {
  content: "\ec27";
}
.fas-khanda:before {
  content: "\ec28";
}
.fas-kidneys:before {
  content: "\ec29";
}
.fas-kiss-beam:before {
  content: "\ec2a";
}
.fas-kiss-wink-heart:before {
  content: "\ec2b";
}
.fas-kiss:before {
  content: "\ec2c";
}
.fas-kite:before {
  content: "\ec2d";
}
.fas-kiwi-bird:before {
  content: "\ec2e";
}
.fas-knife-kitchen:before {
  content: "\ec2f";
}
.fas-lambda:before {
  content: "\ec30";
}
.fas-lamp:before {
  content: "\ec31";
}
.fas-landmark-alt:before {
  content: "\ec32";
}
.fas-landmark:before {
  content: "\ec33";
}
.fas-language:before {
  content: "\ec34";
}
.fas-laptop-code:before {
  content: "\ec35";
}
.fas-laptop-medical:before {
  content: "\ec36";
}
.fas-laptop:before {
  content: "\ec37";
}
.fas-laugh-beam:before {
  content: "\ec38";
}
.fas-laugh-squint:before {
  content: "\ec39";
}
.fas-laugh-wink:before {
  content: "\ec3a";
}
.fas-laugh:before {
  content: "\ec3b";
}
.fas-layer-group:before {
  content: "\ec3c";
}
.fas-layer-minus:before {
  content: "\ec3d";
}
.fas-layer-plus:before {
  content: "\ec3e";
}
.fas-leaf-heart:before {
  content: "\ec3f";
}
.fas-leaf-maple:before {
  content: "\ec40";
}
.fas-leaf-oak:before {
  content: "\ec41";
}
.fas-leaf:before {
  content: "\ec42";
}
.fas-lemon:before {
  content: "\ec43";
}
.fas-less-than-equal:before {
  content: "\ec44";
}
.fas-less-than:before {
  content: "\ec45";
}
.fas-level-down-alt:before {
  content: "\ec46";
}
.fas-level-down:before {
  content: "\ec47";
}
.fas-level-up-alt:before {
  content: "\ec48";
}
.fas-level-up:before {
  content: "\ec49";
}
.fas-life-ring:before {
  content: "\ec4a";
}
.fas-lightbulb-dollar:before {
  content: "\ec4b";
}
.fas-lightbulb-exclamation:before {
  content: "\ec4c";
}
.fas-lightbulb-on:before {
  content: "\ec4d";
}
.fas-lightbulb-slash:before {
  content: "\ec4e";
}
.fas-lightbulb:before {
  content: "\ec4f";
}
.fas-lights-holiday:before {
  content: "\ec50";
}
.fas-link:before {
  content: "\ec51";
}
.fas-lira-sign:before {
  content: "\ec52";
}
.fas-list-alt:before {
  content: "\ec53";
}
.fas-list-ol:before {
  content: "\ec54";
}
.fas-list-ul:before {
  content: "\ec55";
}
.fas-list:before {
  content: "\ec56";
}
.fas-location-arrow:before {
  content: "\ec57";
}
.fas-location-circle:before {
  content: "\ec58";
}
.fas-location-slash:before {
  content: "\ec59";
}
.fas-location:before {
  content: "\ec5a";
}
.fas-lock-alt:before {
  content: "\ec5b";
}
.fas-lock-open-alt:before {
  content: "\ec5c";
}
.fas-lock-open:before {
  content: "\ec5d";
}
.fas-lock:before {
  content: "\ec5e";
}
.fas-long-arrow-alt-down:before {
  content: "\ec5f";
}
.fas-long-arrow-alt-left:before {
  content: "\ec60";
}
.fas-long-arrow-alt-right:before {
  content: "\ec61";
}
.fas-long-arrow-alt-up:before {
  content: "\ec62";
}
.fas-long-arrow-down:before {
  content: "\ec63";
}
.fas-long-arrow-left:before {
  content: "\ec64";
}
.fas-long-arrow-right:before {
  content: "\ec65";
}
.fas-long-arrow-up:before {
  content: "\ec66";
}
.fas-loveseat:before {
  content: "\ec67";
}
.fas-low-vision:before {
  content: "\ec68";
}
.fas-luchador:before {
  content: "\ec69";
}
.fas-luggage-cart:before {
  content: "\ec6a";
}
.fas-lungs:before {
  content: "\ec6b";
}
.fas-mace:before {
  content: "\ec6c";
}
.fas-magic:before {
  content: "\ec6d";
}
.fas-magnet:before {
  content: "\ec6e";
}
.fas-mail-bulk:before {
  content: "\ec6f";
}
.fas-mailbox:before {
  content: "\ec70";
}
.fas-male:before {
  content: "\ec71";
}
.fas-mandolin:before {
  content: "\ec72";
}
.fas-map-marked-alt:before {
  content: "\ec73";
}
.fas-map-marked:before {
  content: "\ec74";
}
.fas-map-marker-alt-slash:before {
  content: "\ec75";
}
.fas-map-marker-alt:before {
  content: "\ec76";
}
.fas-map-marker-check:before {
  content: "\ec77";
}
.fas-map-marker-edit:before {
  content: "\ec78";
}
.fas-map-marker-exclamation:before {
  content: "\ec79";
}
.fas-map-marker-minus:before {
  content: "\ec7a";
}
.fas-map-marker-plus:before {
  content: "\ec7b";
}
.fas-map-marker-question:before {
  content: "\ec7c";
}
.fas-map-marker-slash:before {
  content: "\ec7d";
}
.fas-map-marker-smile:before {
  content: "\ec7e";
}
.fas-map-marker-times:before {
  content: "\ec7f";
}
.fas-map-marker:before {
  content: "\ec80";
}
.fas-map-pin:before {
  content: "\ec81";
}
.fas-map-signs:before {
  content: "\ec82";
}
.fas-map:before {
  content: "\ec83";
}
.fas-marker:before {
  content: "\ec84";
}
.fas-mars-double:before {
  content: "\ec85";
}
.fas-mars-stroke-h:before {
  content: "\ec86";
}
.fas-mars-stroke-v:before {
  content: "\ec87";
}
.fas-mars-stroke:before {
  content: "\ec88";
}
.fas-mars:before {
  content: "\ec89";
}
.fas-mask:before {
  content: "\ec8a";
}
.fas-meat:before {
  content: "\ec8b";
}
.fas-medal:before {
  content: "\ec8c";
}
.fas-medkit:before {
  content: "\ec8d";
}
.fas-megaphone:before {
  content: "\ec8e";
}
.fas-meh-blank:before {
  content: "\ec8f";
}
.fas-meh-rolling-eyes:before {
  content: "\ec90";
}
.fas-meh:before {
  content: "\ec91";
}
.fas-memory:before {
  content: "\ec92";
}
.fas-menorah:before {
  content: "\ec93";
}
.fas-mercury:before {
  content: "\ec94";
}
.fas-meteor:before {
  content: "\ec95";
}
.fas-microchip:before {
  content: "\ec96";
}
.fas-microphone-alt-slash:before {
  content: "\ec97";
}
.fas-microphone-alt:before {
  content: "\ec98";
}
.fas-microphone-slash:before {
  content: "\ec99";
}
.fas-microphone:before {
  content: "\ec9a";
}
.fas-microscope:before {
  content: "\ec9b";
}
.fas-mind-share:before {
  content: "\ec9c";
}
.fas-minus-circle:before {
  content: "\ec9d";
}
.fas-minus-hexagon:before {
  content: "\ec9e";
}
.fas-minus-octagon:before {
  content: "\ec9f";
}
.fas-minus-square:before {
  content: "\eca0";
}
.fas-minus:before {
  content: "\eca1";
}
.fas-mistletoe:before {
  content: "\eca2";
}
.fas-mitten:before {
  content: "\eca3";
}
.fas-mobile-alt:before {
  content: "\eca4";
}
.fas-mobile-android-alt:before {
  content: "\eca5";
}
.fas-mobile-android:before {
  content: "\eca6";
}
.fas-mobile:before {
  content: "\eca7";
}
.fas-money-bill-alt:before {
  content: "\eca8";
}
.fas-money-bill-wave-alt:before {
  content: "\eca9";
}
.fas-money-bill-wave:before {
  content: "\ecaa";
}
.fas-money-bill:before {
  content: "\ecab";
}
.fas-money-check-alt:before {
  content: "\ecac";
}
.fas-money-check:before {
  content: "\ecad";
}
.fas-monitor-heart-rate:before {
  content: "\ecae";
}
.fas-monkey:before {
  content: "\ecaf";
}
.fas-monument:before {
  content: "\ecb0";
}
.fas-moon-cloud:before {
  content: "\ecb1";
}
.fas-moon-stars:before {
  content: "\ecb2";
}
.fas-moon:before {
  content: "\ecb3";
}
.fas-mortar-pestle:before {
  content: "\ecb4";
}
.fas-mosque:before {
  content: "\ecb5";
}
.fas-motorcycle:before {
  content: "\ecb6";
}
.fas-mountain:before {
  content: "\ecb7";
}
.fas-mountains:before {
  content: "\ecb8";
}
.fas-mouse-pointer:before {
  content: "\ecb9";
}
.fas-mug-hot:before {
  content: "\ecba";
}
.fas-mug-marshmallows:before {
  content: "\ecbb";
}
.fas-music:before {
  content: "\ecbc";
}
.fas-narwhal:before {
  content: "\ecbd";
}
.fas-network-wired:before {
  content: "\ecbe";
}
.fas-neuter:before {
  content: "\ecbf";
}
.fas-newspaper:before {
  content: "\ecc0";
}
.fas-not-equal:before {
  content: "\ecc1";
}
.fas-notes-medical:before {
  content: "\ecc2";
}
.fas-object-group:before {
  content: "\ecc3";
}
.fas-object-ungroup:before {
  content: "\ecc4";
}
.fas-octagon:before {
  content: "\ecc5";
}
.fas-oil-can:before {
  content: "\ecc6";
}
.fas-oil-temp:before {
  content: "\ecc7";
}
.fas-om:before {
  content: "\ecc8";
}
.fas-omega:before {
  content: "\ecc9";
}
.fas-ornament:before {
  content: "\ecca";
}
.fas-otter:before {
  content: "\eccb";
}
.fas-outdent:before {
  content: "\eccc";
}
.fas-pager:before {
  content: "\eccd";
}
.fas-paint-brush-alt:before {
  content: "\ecce";
}
.fas-paint-brush:before {
  content: "\eccf";
}
.fas-paint-roller:before {
  content: "\ecd0";
}
.fas-palette:before {
  content: "\ecd1";
}
.fas-pallet-alt:before {
  content: "\ecd2";
}
.fas-pallet:before {
  content: "\ecd3";
}
.fas-paper-plane:before {
  content: "\ecd4";
}
.fas-paperclip:before {
  content: "\ecd5";
}
.fas-parachute-box:before {
  content: "\ecd6";
}
.fas-paragraph:before {
  content: "\ecd7";
}
.fas-parking-circle-slash:before {
  content: "\ecd8";
}
.fas-parking-circle:before {
  content: "\ecd9";
}
.fas-parking-slash:before {
  content: "\ecda";
}
.fas-parking:before {
  content: "\ecdb";
}
.fas-passport:before {
  content: "\ecdc";
}
.fas-pastafarianism:before {
  content: "\ecdd";
}
.fas-paste:before {
  content: "\ecde";
}
.fas-pause-circle:before {
  content: "\ecdf";
}
.fas-pause:before {
  content: "\ece0";
}
.fas-paw-alt:before {
  content: "\ece1";
}
.fas-paw-claws:before {
  content: "\ece2";
}
.fas-paw:before {
  content: "\ece3";
}
.fas-peace:before {
  content: "\ece4";
}
.fas-pegasus:before {
  content: "\ece5";
}
.fas-pen-alt:before {
  content: "\ece6";
}
.fas-pen-fancy:before {
  content: "\ece7";
}
.fas-pen-nib:before {
  content: "\ece8";
}
.fas-pen-square:before {
  content: "\ece9";
}
.fas-pen:before {
  content: "\ecea";
}
.fas-pencil-alt:before {
  content: "\eceb";
}
.fas-pencil-paintbrush:before {
  content: "\ecec";
}
.fas-pencil-ruler:before {
  content: "\eced";
}
.fas-pencil:before {
  content: "\ecee";
}
.fas-pennant:before {
  content: "\ecef";
}
.fas-people-carry:before {
  content: "\ecf0";
}
.fas-pepper-hot:before {
  content: "\ecf1";
}
.fas-percent:before {
  content: "\ecf2";
}
.fas-percentage:before {
  content: "\ecf3";
}
.fas-person-booth:before {
  content: "\ecf4";
}
.fas-person-carry:before {
  content: "\ecf5";
}
.fas-person-dolly-empty:before {
  content: "\ecf6";
}
.fas-person-dolly:before {
  content: "\ecf7";
}
.fas-person-sign:before {
  content: "\ecf8";
}
.fas-phone-office:before {
  content: "\ecf9";
}
.fas-phone-plus:before {
  content: "\ecfa";
}
.fas-phone-slash:before {
  content: "\ecfb";
}
.fas-phone-square:before {
  content: "\ecfc";
}
.fas-phone-volume:before {
  content: "\ecfd";
}
.fas-phone:before {
  content: "\ecfe";
}
.fas-pi:before {
  content: "\ecff";
}
.fas-pie:before {
  content: "\ed00";
}
.fas-pig:before {
  content: "\ed01";
}
.fas-piggy-bank:before {
  content: "\ed02";
}
.fas-pills:before {
  content: "\ed03";
}
.fas-pizza-slice:before {
  content: "\ed04";
}
.fas-pizza:before {
  content: "\ed05";
}
.fas-place-of-worship:before {
  content: "\ed06";
}
.fas-plane-alt:before {
  content: "\ed07";
}
.fas-plane-arrival:before {
  content: "\ed08";
}
.fas-plane-departure:before {
  content: "\ed09";
}
.fas-plane:before {
  content: "\ed0a";
}
.fas-play-circle:before {
  content: "\ed0b";
}
.fas-play:before {
  content: "\ed0c";
}
.fas-plug:before {
  content: "\ed0d";
}
.fas-plus-circle:before {
  content: "\ed0e";
}
.fas-plus-hexagon:before {
  content: "\ed0f";
}
.fas-plus-octagon:before {
  content: "\ed10";
}
.fas-plus-square:before {
  content: "\ed11";
}
.fas-plus:before {
  content: "\ed12";
}
.fas-podcast:before {
  content: "\ed13";
}
.fas-podium-star:before {
  content: "\ed14";
}
.fas-podium:before {
  content: "\ed15";
}
.fas-poll-h:before {
  content: "\ed16";
}
.fas-poll-people:before {
  content: "\ed17";
}
.fas-poll:before {
  content: "\ed18";
}
.fas-poo-storm:before {
  content: "\ed19";
}
.fas-poo:before {
  content: "\ed1a";
}
.fas-poop:before {
  content: "\ed1b";
}
.fas-popcorn:before {
  content: "\ed1c";
}
.fas-portrait:before {
  content: "\ed1d";
}
.fas-pound-sign:before {
  content: "\ed1e";
}
.fas-power-off:before {
  content: "\ed1f";
}
.fas-pray:before {
  content: "\ed20";
}
.fas-praying-hands:before {
  content: "\ed21";
}
.fas-prescription-bottle-alt:before {
  content: "\ed22";
}
.fas-prescription-bottle:before {
  content: "\ed23";
}
.fas-prescription:before {
  content: "\ed24";
}
.fas-presentation:before {
  content: "\ed25";
}
.fas-print-search:before {
  content: "\ed26";
}
.fas-print-slash:before {
  content: "\ed27";
}
.fas-print:before {
  content: "\ed28";
}
.fas-procedures:before {
  content: "\ed29";
}
.fas-project-diagram:before {
  content: "\ed2a";
}
.fas-pumpkin:before {
  content: "\ed2b";
}
.fas-puzzle-piece:before {
  content: "\ed2c";
}
.fas-qrcode:before {
  content: "\ed2d";
}
.fas-question-circle:before {
  content: "\ed2e";
}
.fas-question-square:before {
  content: "\ed2f";
}
.fas-question:before {
  content: "\ed30";
}
.fas-quidditch:before {
  content: "\ed31";
}
.fas-quote-left:before {
  content: "\ed32";
}
.fas-quote-right:before {
  content: "\ed33";
}
.fas-quran:before {
  content: "\ed34";
}
.fas-rabbit-fast:before {
  content: "\ed35";
}
.fas-rabbit:before {
  content: "\ed36";
}
.fas-racquet:before {
  content: "\ed37";
}
.fas-radiation-alt:before {
  content: "\ed38";
}
.fas-radiation:before {
  content: "\ed39";
}
.fas-rainbow:before {
  content: "\ed3a";
}
.fas-raindrops:before {
  content: "\ed3b";
}
.fas-ram:before {
  content: "\ed3c";
}
.fas-ramp-loading:before {
  content: "\ed3d";
}
.fas-random:before {
  content: "\ed3e";
}
.fas-receipt:before {
  content: "\ed3f";
}
.fas-rectangle-landscape:before {
  content: "\ed40";
}
.fas-rectangle-portrait:before {
  content: "\ed41";
}
.fas-rectangle-wide:before {
  content: "\ed42";
}
.fas-recycle:before {
  content: "\ed43";
}
.fas-redo-alt:before {
  content: "\ed44";
}
.fas-redo:before {
  content: "\ed45";
}
.fas-registered:before {
  content: "\ed46";
}
.fas-repeat-1-alt:before {
  content: "\ed47";
}
.fas-repeat-1:before {
  content: "\ed48";
}
.fas-repeat-alt:before {
  content: "\ed49";
}
.fas-repeat:before {
  content: "\ed4a";
}
.fas-reply-all:before {
  content: "\ed4b";
}
.fas-reply:before {
  content: "\ed4c";
}
.fas-republican:before {
  content: "\ed4d";
}
.fas-restroom:before {
  content: "\ed4e";
}
.fas-retweet-alt:before {
  content: "\ed4f";
}
.fas-retweet:before {
  content: "\ed50";
}
.fas-ribbon:before {
  content: "\ed51";
}
.fas-ring:before {
  content: "\ed52";
}
.fas-rings-wedding:before {
  content: "\ed53";
}
.fas-road:before {
  content: "\ed54";
}
.fas-robot:before {
  content: "\ed55";
}
.fas-rocket:before {
  content: "\ed56";
}
.fas-route-highway:before {
  content: "\ed57";
}
.fas-route-interstate:before {
  content: "\ed58";
}
.fas-route:before {
  content: "\ed59";
}
.fas-rss-square:before {
  content: "\ed5a";
}
.fas-rss:before {
  content: "\ed5b";
}
.fas-ruble-sign:before {
  content: "\ed5c";
}
.fas-ruler-combined:before {
  content: "\ed5d";
}
.fas-ruler-horizontal:before {
  content: "\ed5e";
}
.fas-ruler-triangle:before {
  content: "\ed5f";
}
.fas-ruler-vertical:before {
  content: "\ed60";
}
.fas-ruler:before {
  content: "\ed61";
}
.fas-running:before {
  content: "\ed62";
}
.fas-rupee-sign:before {
  content: "\ed63";
}
.fas-rv:before {
  content: "\ed64";
}
.fas-sack-dollar:before {
  content: "\ed65";
}
.fas-sack:before {
  content: "\ed66";
}
.fas-sad-cry:before {
  content: "\ed67";
}
.fas-sad-tear:before {
  content: "\ed68";
}
.fas-salad:before {
  content: "\ed69";
}
.fas-sandwich:before {
  content: "\ed6a";
}
.fas-satellite-dish:before {
  content: "\ed6b";
}
.fas-satellite:before {
  content: "\ed6c";
}
.fas-sausage:before {
  content: "\ed6d";
}
.fas-save:before {
  content: "\ed6e";
}
.fas-scalpel-path:before {
  content: "\ed6f";
}
.fas-scalpel:before {
  content: "\ed70";
}
.fas-scanner-keyboard:before {
  content: "\ed71";
}
.fas-scanner-touchscreen:before {
  content: "\ed72";
}
.fas-scanner:before {
  content: "\ed73";
}
.fas-scarecrow:before {
  content: "\ed74";
}
.fas-scarf:before {
  content: "\ed75";
}
.fas-school:before {
  content: "\ed76";
}
.fas-screwdriver:before {
  content: "\ed77";
}
.fas-scroll-old:before {
  content: "\ed78";
}
.fas-scroll:before {
  content: "\ed79";
}
.fas-scrubber:before {
  content: "\ed7a";
}
.fas-scythe:before {
  content: "\ed7b";
}
.fas-sd-card:before {
  content: "\ed7c";
}
.fas-search-dollar:before {
  content: "\ed7d";
}
.fas-search-location:before {
  content: "\ed7e";
}
.fas-search-minus:before {
  content: "\ed7f";
}
.fas-search-plus:before {
  content: "\ed80";
}
.fas-search:before {
  content: "\ed81";
}
.fas-seedling:before {
  content: "\ed82";
}
.fas-server:before {
  content: "\ed83";
}
.fas-shapes:before {
  content: "\ed84";
}
.fas-share-all:before {
  content: "\ed85";
}
.fas-share-alt-square:before {
  content: "\ed86";
}
.fas-share-alt:before {
  content: "\ed87";
}
.fas-share-square:before {
  content: "\ed88";
}
.fas-share:before {
  content: "\ed89";
}
.fas-sheep:before {
  content: "\ed8a";
}
.fas-shekel-sign:before {
  content: "\ed8b";
}
.fas-shield-alt:before {
  content: "\ed8c";
}
.fas-shield-check:before {
  content: "\ed8d";
}
.fas-shield-cross:before {
  content: "\ed8e";
}
.fas-shield:before {
  content: "\ed8f";
}
.fas-ship:before {
  content: "\ed90";
}
.fas-shipping-fast:before {
  content: "\ed91";
}
.fas-shipping-timed:before {
  content: "\ed92";
}
.fas-shish-kebab:before {
  content: "\ed93";
}
.fas-shoe-prints:before {
  content: "\ed94";
}
.fas-shopping-bag:before {
  content: "\ed95";
}
.fas-shopping-basket:before {
  content: "\ed96";
}
.fas-shopping-cart:before {
  content: "\ed97";
}
.fas-shovel-snow:before {
  content: "\ed98";
}
.fas-shovel:before {
  content: "\ed99";
}
.fas-shower:before {
  content: "\ed9a";
}
.fas-shredder:before {
  content: "\ed9b";
}
.fas-shuttle-van:before {
  content: "\ed9c";
}
.fas-shuttlecock:before {
  content: "\ed9d";
}
.fas-sickle:before {
  content: "\ed9e";
}
.fas-sigma:before {
  content: "\ed9f";
}
.fas-sign-in-alt:before {
  content: "\eda0";
}
.fas-sign-in:before {
  content: "\eda1";
}
.fas-sign-language:before {
  content: "\eda2";
}
.fas-sign-out-alt:before {
  content: "\eda3";
}
.fas-sign-out:before {
  content: "\eda4";
}
.fas-sign:before {
  content: "\eda5";
}
.fas-signal-1:before {
  content: "\eda6";
}
.fas-signal-2:before {
  content: "\eda7";
}
.fas-signal-3:before {
  content: "\eda8";
}
.fas-signal-4:before {
  content: "\eda9";
}
.fas-signal-alt-1:before {
  content: "\edaa";
}
.fas-signal-alt-2:before {
  content: "\edab";
}
.fas-signal-alt-3:before {
  content: "\edac";
}
.fas-signal-alt-slash:before {
  content: "\edad";
}
.fas-signal-alt:before {
  content: "\edae";
}
.fas-signal-slash:before {
  content: "\edaf";
}
.fas-signal:before {
  content: "\edb0";
}
.fas-signature:before {
  content: "\edb1";
}
.fas-sim-card:before {
  content: "\edb2";
}
.fas-sitemap:before {
  content: "\edb3";
}
.fas-skating:before {
  content: "\edb4";
}
.fas-skeleton:before {
  content: "\edb5";
}
.fas-ski-jump:before {
  content: "\edb6";
}
.fas-ski-lift:before {
  content: "\edb7";
}
.fas-skiing-nordic:before {
  content: "\edb8";
}
.fas-skiing:before {
  content: "\edb9";
}
.fas-skull-crossbones:before {
  content: "\edba";
}
.fas-skull:before {
  content: "\edbb";
}
.fas-slash:before {
  content: "\edbc";
}
.fas-sledding:before {
  content: "\edbd";
}
.fas-sleigh:before {
  content: "\edbe";
}
.fas-sliders-h-square:before {
  content: "\edbf";
}
.fas-sliders-h:before {
  content: "\edc0";
}
.fas-sliders-v-square:before {
  content: "\edc1";
}
.fas-sliders-v:before {
  content: "\edc2";
}
.fas-smile-beam:before {
  content: "\edc3";
}
.fas-smile-plus:before {
  content: "\edc4";
}
.fas-smile-wink:before {
  content: "\edc5";
}
.fas-smile:before {
  content: "\edc6";
}
.fas-smog:before {
  content: "\edc7";
}
.fas-smoke:before {
  content: "\edc8";
}
.fas-smoking-ban:before {
  content: "\edc9";
}
.fas-smoking:before {
  content: "\edca";
}
.fas-sms:before {
  content: "\edcb";
}
.fas-snake:before {
  content: "\edcc";
}
.fas-snow-blowing:before {
  content: "\edcd";
}
.fas-snowboarding:before {
  content: "\edce";
}
.fas-snowflake:before {
  content: "\edcf";
}
.fas-snowflakes:before {
  content: "\edd0";
}
.fas-snowman:before {
  content: "\edd1";
}
.fas-snowmobile:before {
  content: "\edd2";
}
.fas-snowplow:before {
  content: "\edd3";
}
.fas-socks:before {
  content: "\edd4";
}
.fas-solar-panel:before {
  content: "\edd5";
}
.fas-sort-alpha-down:before {
  content: "\edd6";
}
.fas-sort-alpha-up:before {
  content: "\edd7";
}
.fas-sort-amount-down:before {
  content: "\edd8";
}
.fas-sort-amount-up:before {
  content: "\edd9";
}
.fas-sort-down:before {
  content: "\edda";
}
.fas-sort-numeric-down:before {
  content: "\eddb";
}
.fas-sort-numeric-up:before {
  content: "\eddc";
}
.fas-sort-up:before {
  content: "\eddd";
}
.fas-sort:before {
  content: "\edde";
}
.fas-soup:before {
  content: "\eddf";
}
.fas-spa:before {
  content: "\ede0";
}
.fas-space-shuttle:before {
  content: "\ede1";
}
.fas-spade:before {
  content: "\ede2";
}
.fas-spider-black-widow:before {
  content: "\ede3";
}
.fas-spider-web:before {
  content: "\ede4";
}
.fas-spider:before {
  content: "\ede5";
}
.fas-spinner-third:before {
  content: "\ede6";
}
.fas-spinner:before {
  content: "\ede7";
}
.fas-splotch:before {
  content: "\ede8";
}
.fas-spray-can:before {
  content: "\ede9";
}
.fas-square-full:before {
  content: "\edea";
}
.fas-square-root-alt:before {
  content: "\edeb";
}
.fas-square-root:before {
  content: "\edec";
}
.fas-square:before {
  content: "\eded";
}
.fas-squirrel:before {
  content: "\edee";
}
.fas-staff:before {
  content: "\edef";
}
.fas-stamp:before {
  content: "\edf0";
}
.fas-star-and-crescent:before {
  content: "\edf1";
}
.fas-star-christmas:before {
  content: "\edf2";
}
.fas-star-exclamation:before {
  content: "\edf3";
}
.fas-star-half-alt:before {
  content: "\edf4";
}
.fas-star-half:before {
  content: "\edf5";
}
.fas-star-of-david:before {
  content: "\edf6";
}
.fas-star-of-life:before {
  content: "\edf7";
}
.fas-star:before {
  content: "\edf8";
}
.fas-stars:before {
  content: "\edf9";
}
.fas-steak:before {
  content: "\edfa";
}
.fas-steering-wheel:before {
  content: "\edfb";
}
.fas-step-backward:before {
  content: "\edfc";
}
.fas-step-forward:before {
  content: "\edfd";
}
.fas-stethoscope:before {
  content: "\edfe";
}
.fas-sticky-note:before {
  content: "\edff";
}
.fas-stocking:before {
  content: "\ee00";
}
.fas-stomach:before {
  content: "\ee01";
}
.fas-stop-circle:before {
  content: "\ee02";
}
.fas-stop:before {
  content: "\ee03";
}
.fas-stopwatch:before {
  content: "\ee04";
}
.fas-store-alt:before {
  content: "\ee05";
}
.fas-store:before {
  content: "\ee06";
}
.fas-stream:before {
  content: "\ee07";
}
.fas-street-view:before {
  content: "\ee08";
}
.fas-stretcher:before {
  content: "\ee09";
}
.fas-strikethrough:before {
  content: "\ee0a";
}
.fas-stroopwafel:before {
  content: "\ee0b";
}
.fas-subscript:before {
  content: "\ee0c";
}
.fas-subway:before {
  content: "\ee0d";
}
.fas-suitcase-rolling:before {
  content: "\ee0e";
}
.fas-suitcase:before {
  content: "\ee0f";
}
.fas-sun-cloud:before {
  content: "\ee10";
}
.fas-sun-dust:before {
  content: "\ee11";
}
.fas-sun-haze:before {
  content: "\ee12";
}
.fas-sun:before {
  content: "\ee13";
}
.fas-sunrise:before {
  content: "\ee14";
}
.fas-sunset:before {
  content: "\ee15";
}
.fas-superscript:before {
  content: "\ee16";
}
.fas-surprise:before {
  content: "\ee17";
}
.fas-swatchbook:before {
  content: "\ee18";
}
.fas-swimmer:before {
  content: "\ee19";
}
.fas-swimming-pool:before {
  content: "\ee1a";
}
.fas-sword:before {
  content: "\ee1b";
}
.fas-swords:before {
  content: "\ee1c";
}
.fas-synagogue:before {
  content: "\ee1d";
}
.fas-sync-alt:before {
  content: "\ee1e";
}
.fas-sync:before {
  content: "\ee1f";
}
.fas-syringe:before {
  content: "\ee20";
}
.fas-table-tennis:before {
  content: "\ee21";
}
.fas-table:before {
  content: "\ee22";
}
.fas-tablet-alt:before {
  content: "\ee23";
}
.fas-tablet-android-alt:before {
  content: "\ee24";
}
.fas-tablet-android:before {
  content: "\ee25";
}
.fas-tablet-rugged:before {
  content: "\ee26";
}
.fas-tablet:before {
  content: "\ee27";
}
.fas-tablets:before {
  content: "\ee28";
}
.fas-tachometer-alt-average:before {
  content: "\ee29";
}
.fas-tachometer-alt-fast:before {
  content: "\ee2a";
}
.fas-tachometer-alt-fastest:before {
  content: "\ee2b";
}
.fas-tachometer-alt-slow:before {
  content: "\ee2c";
}
.fas-tachometer-alt-slowest:before {
  content: "\ee2d";
}
.fas-tachometer-alt:before {
  content: "\ee2e";
}
.fas-tachometer-average:before {
  content: "\ee2f";
}
.fas-tachometer-fast:before {
  content: "\ee30";
}
.fas-tachometer-fastest:before {
  content: "\ee31";
}
.fas-tachometer-slow:before {
  content: "\ee32";
}
.fas-tachometer-slowest:before {
  content: "\ee33";
}
.fas-tachometer:before {
  content: "\ee34";
}
.fas-taco:before {
  content: "\ee35";
}
.fas-tag:before {
  content: "\ee36";
}
.fas-tags:before {
  content: "\ee37";
}
.fas-tally:before {
  content: "\ee38";
}
.fas-tanakh:before {
  content: "\ee39";
}
.fas-tape:before {
  content: "\ee3a";
}
.fas-tasks-alt:before {
  content: "\ee3b";
}
.fas-tasks:before {
  content: "\ee3c";
}
.fas-taxi:before {
  content: "\ee3d";
}
.fas-teeth-open:before {
  content: "\ee3e";
}
.fas-teeth:before {
  content: "\ee3f";
}
.fas-temperature-frigid:before {
  content: "\ee40";
}
.fas-temperature-high:before {
  content: "\ee41";
}
.fas-temperature-hot:before {
  content: "\ee42";
}
.fas-temperature-low:before {
  content: "\ee43";
}
.fas-tenge:before {
  content: "\ee44";
}
.fas-tennis-ball:before {
  content: "\ee45";
}
.fas-terminal:before {
  content: "\ee46";
}
.fas-text-height:before {
  content: "\ee47";
}
.fas-text-width:before {
  content: "\ee48";
}
.fas-th-large:before {
  content: "\ee49";
}
.fas-th-list:before {
  content: "\ee4a";
}
.fas-th:before {
  content: "\ee4b";
}
.fas-theater-masks:before {
  content: "\ee4c";
}
.fas-thermometer-empty:before {
  content: "\ee4d";
}
.fas-thermometer-full:before {
  content: "\ee4e";
}
.fas-thermometer-half:before {
  content: "\ee4f";
}
.fas-thermometer-quarter:before {
  content: "\ee50";
}
.fas-thermometer-three-quarters:before {
  content: "\ee51";
}
.fas-thermometer:before {
  content: "\ee52";
}
.fas-theta:before {
  content: "\ee53";
}
.fas-thumbs-down:before {
  content: "\ee54";
}
.fas-thumbs-up:before {
  content: "\ee55";
}
.fas-thumbtack:before {
  content: "\ee56";
}
.fas-thunderstorm-moon:before {
  content: "\ee57";
}
.fas-thunderstorm-sun:before {
  content: "\ee58";
}
.fas-thunderstorm:before {
  content: "\ee59";
}
.fas-ticket-alt:before {
  content: "\ee5a";
}
.fas-ticket:before {
  content: "\ee5b";
}
.fas-tilde:before {
  content: "\ee5c";
}
.fas-times-circle:before {
  content: "\ee5d";
}
.fas-times-hexagon:before {
  content: "\ee5e";
}
.fas-times-octagon:before {
  content: "\ee5f";
}
.fas-times-square:before {
  content: "\ee60";
}
.fas-times:before {
  content: "\ee61";
}
.fas-tint-slash:before {
  content: "\ee62";
}
.fas-tint:before {
  content: "\ee63";
}
.fas-tire-flat:before {
  content: "\ee64";
}
.fas-tire-pressure-warning:before {
  content: "\ee65";
}
.fas-tire-rugged:before {
  content: "\ee66";
}
.fas-tire:before {
  content: "\ee67";
}
.fas-tired:before {
  content: "\ee68";
}
.fas-toggle-off:before {
  content: "\ee69";
}
.fas-toggle-on:before {
  content: "\ee6a";
}
.fas-toilet-paper-alt:before {
  content: "\ee6b";
}
.fas-toilet-paper:before {
  content: "\ee6c";
}
.fas-toilet:before {
  content: "\ee6d";
}
.fas-tombstone-alt:before {
  content: "\ee6e";
}
.fas-tombstone:before {
  content: "\ee6f";
}
.fas-toolbox:before {
  content: "\ee70";
}
.fas-tools:before {
  content: "\ee71";
}
.fas-tooth:before {
  content: "\ee72";
}
.fas-toothbrush:before {
  content: "\ee73";
}
.fas-torah:before {
  content: "\ee74";
}
.fas-torii-gate:before {
  content: "\ee75";
}
.fas-tornado:before {
  content: "\ee76";
}
.fas-tractor:before {
  content: "\ee77";
}
.fas-trademark:before {
  content: "\ee78";
}
.fas-traffic-cone:before {
  content: "\ee79";
}
.fas-traffic-light-go:before {
  content: "\ee7a";
}
.fas-traffic-light-slow:before {
  content: "\ee7b";
}
.fas-traffic-light-stop:before {
  content: "\ee7c";
}
.fas-traffic-light:before {
  content: "\ee7d";
}
.fas-train:before {
  content: "\ee7e";
}
.fas-tram:before {
  content: "\ee7f";
}
.fas-transgender-alt:before {
  content: "\ee80";
}
.fas-transgender:before {
  content: "\ee81";
}
.fas-trash-alt:before {
  content: "\ee82";
}
.fas-trash-restore-alt:before {
  content: "\ee83";
}
.fas-trash-restore:before {
  content: "\ee84";
}
.fas-trash:before {
  content: "\ee85";
}
.fas-treasure-chest:before {
  content: "\ee86";
}
.fas-tree-alt:before {
  content: "\ee87";
}
.fas-tree-christmas:before {
  content: "\ee88";
}
.fas-tree-decorated:before {
  content: "\ee89";
}
.fas-tree-large:before {
  content: "\ee8a";
}
.fas-tree-palm:before {
  content: "\ee8b";
}
.fas-tree:before {
  content: "\ee8c";
}
.fas-trees:before {
  content: "\ee8d";
}
.fas-triangle:before {
  content: "\ee8e";
}
.fas-trophy-alt:before {
  content: "\ee8f";
}
.fas-trophy:before {
  content: "\ee90";
}
.fas-truck-container:before {
  content: "\ee91";
}
.fas-truck-couch:before {
  content: "\ee92";
}
.fas-truck-loading:before {
  content: "\ee93";
}
.fas-truck-monster:before {
  content: "\ee94";
}
.fas-truck-moving:before {
  content: "\ee95";
}
.fas-truck-pickup:before {
  content: "\ee96";
}
.fas-truck-plow:before {
  content: "\ee97";
}
.fas-truck-ramp:before {
  content: "\ee98";
}
.fas-truck:before {
  content: "\ee99";
}
.fas-tshirt:before {
  content: "\ee9a";
}
.fas-tty:before {
  content: "\ee9b";
}
.fas-turkey:before {
  content: "\ee9c";
}
.fas-turtle:before {
  content: "\ee9d";
}
.fas-tv-retro:before {
  content: "\ee9e";
}
.fas-tv:before {
  content: "\ee9f";
}
.fas-umbrella-beach:before {
  content: "\eea0";
}
.fas-umbrella:before {
  content: "\eea1";
}
.fas-underline:before {
  content: "\eea2";
}
.fas-undo-alt:before {
  content: "\eea3";
}
.fas-undo:before {
  content: "\eea4";
}
.fas-unicorn:before {
  content: "\eea5";
}
.fas-union:before {
  content: "\eea6";
}
.fas-universal-access:before {
  content: "\eea7";
}
.fas-university:before {
  content: "\eea8";
}
.fas-unlink:before {
  content: "\eea9";
}
.fas-unlock-alt:before {
  content: "\eeaa";
}
.fas-unlock:before {
  content: "\eeab";
}
.fas-upload:before {
  content: "\eeac";
}
.fas-usd-circle:before {
  content: "\eead";
}
.fas-usd-square:before {
  content: "\eeae";
}
.fas-user-alt-slash:before {
  content: "\eeaf";
}
.fas-user-alt:before {
  content: "\eeb0";
}
.fas-user-astronaut:before {
  content: "\eeb1";
}
.fas-user-chart:before {
  content: "\eeb2";
}
.fas-user-check:before {
  content: "\eeb3";
}
.fas-user-circle:before {
  content: "\eeb4";
}
.fas-user-clock:before {
  content: "\eeb5";
}
.fas-user-cog:before {
  content: "\eeb6";
}
.fas-user-crown:before {
  content: "\eeb7";
}
.fas-user-edit:before {
  content: "\eeb8";
}
.fas-user-friends:before {
  content: "\eeb9";
}
.fas-user-graduate:before {
  content: "\eeba";
}
.fas-user-hard-hat:before {
  content: "\eebb";
}
.fas-user-headset:before {
  content: "\eebc";
}
.fas-user-injured:before {
  content: "\eebd";
}
.fas-user-lock:before {
  content: "\eebe";
}
.fas-user-md-chat:before {
  content: "\eebf";
}
.fas-user-md:before {
  content: "\eec0";
}
.fas-user-minus:before {
  content: "\eec1";
}
.fas-user-ninja:before {
  content: "\eec2";
}
.fas-user-nurse:before {
  content: "\eec3";
}
.fas-user-plus:before {
  content: "\eec4";
}
.fas-user-secret:before {
  content: "\eec5";
}
.fas-user-shield:before {
  content: "\eec6";
}
.fas-user-slash:before {
  content: "\eec7";
}
.fas-user-tag:before {
  content: "\eec8";
}
.fas-user-tie:before {
  content: "\eec9";
}
.fas-user-times:before {
  content: "\eeca";
}
.fas-user:before {
  content: "\eecb";
}
.fas-users-class:before {
  content: "\eecc";
}
.fas-users-cog:before {
  content: "\eecd";
}
.fas-users-crown:before {
  content: "\eece";
}
.fas-users-medical:before {
  content: "\eecf";
}
.fas-users:before {
  content: "\eed0";
}
.fas-utensil-fork:before {
  content: "\eed1";
}
.fas-utensil-knife:before {
  content: "\eed2";
}
.fas-utensil-spoon:before {
  content: "\eed3";
}
.fas-utensils-alt:before {
  content: "\eed4";
}
.fas-utensils:before {
  content: "\eed5";
}
.fas-value-absolute:before {
  content: "\eed6";
}
.fas-vector-square:before {
  content: "\eed7";
}
.fas-venus-double:before {
  content: "\eed8";
}
.fas-venus-mars:before {
  content: "\eed9";
}
.fas-venus:before {
  content: "\eeda";
}
.fas-vial:before {
  content: "\eedb";
}
.fas-vials:before {
  content: "\eedc";
}
.fas-video-plus:before {
  content: "\eedd";
}
.fas-video-slash:before {
  content: "\eede";
}
.fas-video:before {
  content: "\eedf";
}
.fas-vihara:before {
  content: "\eee0";
}
.fas-volcano:before {
  content: "\eee1";
}
.fas-volleyball-ball:before {
  content: "\eee2";
}
.fas-volume-down:before {
  content: "\eee3";
}
.fas-volume-mute:before {
  content: "\eee4";
}
.fas-volume-off:before {
  content: "\eee5";
}
.fas-volume-slash:before {
  content: "\eee6";
}
.fas-volume-up:before {
  content: "\eee7";
}
.fas-volume:before {
  content: "\eee8";
}
.fas-vote-nay:before {
  content: "\eee9";
}
.fas-vote-yea:before {
  content: "\eeea";
}
.fas-vr-cardboard:before {
  content: "\eeeb";
}
.fas-walker:before {
  content: "\eeec";
}
.fas-walking:before {
  content: "\eeed";
}
.fas-wallet:before {
  content: "\eeee";
}
.fas-wand-magic:before {
  content: "\eeef";
}
.fas-wand:before {
  content: "\eef0";
}
.fas-warehouse-alt:before {
  content: "\eef1";
}
.fas-warehouse:before {
  content: "\eef2";
}
.fas-watch-fitness:before {
  content: "\eef3";
}
.fas-watch:before {
  content: "\eef4";
}
.fas-water-lower:before {
  content: "\eef5";
}
.fas-water-rise:before {
  content: "\eef6";
}
.fas-water:before {
  content: "\eef7";
}
.fas-wave-square:before {
  content: "\eef8";
}
.fas-webcam-slash:before {
  content: "\eef9";
}
.fas-webcam:before {
  content: "\eefa";
}
.fas-weight-hanging:before {
  content: "\eefb";
}
.fas-weight:before {
  content: "\eefc";
}
.fas-whale:before {
  content: "\eefd";
}
.fas-wheat:before {
  content: "\eefe";
}
.fas-wheelchair:before {
  content: "\eeff";
}
.fas-whistle:before {
  content: "\ef00";
}
.fas-wifi-1:before {
  content: "\ef01";
}
.fas-wifi-2:before {
  content: "\ef02";
}
.fas-wifi-slash:before {
  content: "\ef03";
}
.fas-wifi:before {
  content: "\ef04";
}
.fas-wind-warning:before {
  content: "\ef05";
}
.fas-wind:before {
  content: "\ef06";
}
.fas-window-alt:before {
  content: "\ef07";
}
.fas-window-close:before {
  content: "\ef08";
}
.fas-window-maximize:before {
  content: "\ef09";
}
.fas-window-minimize:before {
  content: "\ef0a";
}
.fas-window-restore:before {
  content: "\ef0b";
}
.fas-window:before {
  content: "\ef0c";
}
.fas-windsock:before {
  content: "\ef0d";
}
.fas-wine-bottle:before {
  content: "\ef0e";
}
.fas-wine-glass-alt:before {
  content: "\ef0f";
}
.fas-wine-glass:before {
  content: "\ef10";
}
.fas-won-sign:before {
  content: "\ef11";
}
.fas-wreath:before {
  content: "\ef12";
}
.fas-wrench:before {
  content: "\ef13";
}
.fas-x-ray:before {
  content: "\ef14";
}
.fas-yen-sign:before {
  content: "\ef15";
}
.fas-yin-yang:before {
  content: "\ef16";
}
