body{
    margin: 0;
}
@media only screen and (min-width: 1441px){
    .section{
        margin: 0;
        max-width: 1440px;
        margin: auto;
    }
}
